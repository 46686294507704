import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'SearchPipe'
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], str): any
  {
    if (str === undefined || str === '') return items;
    let filtered = [];

    //words = str.replace(" ", "|");

    let words = str.replace(new RegExp(" ", 'g'), "|");

    let rgx = new RegExp(words, 'gi');

    items.forEach(item => {
      item.points = (JSON.stringify(item).match(rgx) || []).length;
      if (item.points > 0) filtered.push(item);
    });

    return filtered;
  }
}
