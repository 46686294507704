import {Component, EventEmitter, Input, OnInit, Output, ViewChildren} from '@angular/core';
import { Theme } from "../../Entities/Theme";
import { SearchCriteria } from "../../Entities/SearchCriteria";
import { ThemePaginateModel } from "../../Entities/ThemePaginateModel";

@Component({
  selector: "app-themes-controller-view",
  templateUrl: "./themes-controller-view.component.html",
  styleUrls: ["./themes-controller-view.component.css"]
})
export class ThemesControllerViewComponent implements OnInit {

  @ViewChildren('inputSearch') inputSearchViewChild;


  @Input()
  currentPage: number;
  @Input()
  pageSize: number;
  @Input()
  themeCount: number;
  @Input()
  savingThemeToDatabase: boolean;
  @Input()
  themes: Theme[];

  @Output()
  public tryCreateThemeEmitter = new EventEmitter();
  @Output()
  public tryDeleteThemeEmitter = new EventEmitter();
  @Output()
  public trySetCurrentPage = new EventEmitter();
  @Output()
  public trySetPageSize = new EventEmitter();
  @Output()
  public tryGetThemesEmitter = new EventEmitter();
  @Output()
  public trySubscribeEmitter = new EventEmitter();
  @Output()
  public tryUnSubscribeEmitter = new EventEmitter();
  @Output()
  public lastPageIndex: number;

  @Output()
  public tryChangeSortParameter = new EventEmitter();
  @Output()
  public tryChangeSortReversed = new EventEmitter();
  @Output()
  public tryChangeSearchInput = new EventEmitter();

  @Output()
  public tryRouteToTheme = new EventEmitter();

  public siteTypes = ["card", "table"];

  public siteType = this.siteTypes[1];

  @Input()
  searchInput : string;
  @Input()
  sortFields : string[];
  @Input()
  sortParameter: string;
  @Input()
  sortReversed: boolean;



  themeToCreate: Theme;
  themeToDelete: Theme;
  isCreating: boolean;

  constructor() {
    this.themeToCreate = new Theme();
    this.themeToDelete = new Theme();
    this.themeToCreate.SearchCriterias = [];
  }

  ngOnInit() {

  }

  ngAfterViewInit()
  {
    this.inputSearchViewChild.first.nativeElement.focus();
  }

  // 221019 NM Sorting
  /*setOrderParameter(para : string)
  {
    if(this.orderParameter == para)
    {
      this.sortReverse = !this.sortReverse;
    }
    this.orderParameter = para;
  }*/

  setSiteType(type : string)
  {
    this.siteType = type;
  }

  tryRemoveSearchcriteria(criteria: SearchCriteria) {
    this.themeToCreate.SearchCriterias.splice(
      this.themeToCreate.SearchCriterias.indexOf(
        this.themeToCreate.SearchCriterias.find(
          x => x.Criteria == criteria.Criteria
        )
      ),
      1
    );
  }

  tryAddSearchCriteria(criteria: any) {
    var newCriteria = new SearchCriteria();
    newCriteria.Criteria = criteria.display;
    this.themeToCreate.SearchCriterias.pop();
    this.themeToCreate.SearchCriterias.push(newCriteria);
  }

  setIsCreating() {
    this.isCreating = !this.isCreating;
  }

  tryCreateTheme() {
    this.tryCreateThemeEmitter.emit(this.themeToCreate);
    this.setIsCreating();
    this.themeToCreate = new Theme();
    this.themeToCreate.SearchCriterias = [];
  }

  getLastPageIndex() {
    return (this.lastPageIndex = Math.ceil(this.themeCount / this.pageSize));
  }

  changeSearchInput(input : string)
  {
    this.tryChangeSearchInput.emit(input);
  }

  getThemesForPage(pageIndex: number) {
    if (pageIndex <= this.lastPageIndex && pageIndex > 0) {
      this.trySetCurrentPage.emit(pageIndex);
      this.tryGetThemesEmitter.emit();
    }
  }

  tryDeleteTheme() {
    if (this.themeToDelete != null) {
      this.tryDeleteThemeEmitter.emit(this.themeToDelete);
    }
  }

  setThemeToDelete(theme: Theme) {
    this.themeToDelete = theme;
  }

  trySubscribeToTheme(theme: Theme) {
    this.trySubscribeEmitter.emit(theme);
  }

  tryUnSubscribeFromTheme(theme: Theme) {
    this.tryUnSubscribeEmitter.emit(theme);
  }

  // 221019 NM Sorting
  setOrderBy(order: string) {
    if (this.sortParameter === order) {
      this.tryChangeSortReversed.emit(!this.sortReversed);
    } else {

      this.tryChangeSortParameter.emit(order);
      this.tryChangeSortReversed.emit(false);
    }

    this.getThemesForPage(this.currentPage);
  }

  themeClicked(theme: Theme) {
    this.tryRouteToTheme.emit(theme);
  }
}

