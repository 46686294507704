import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Email} from '../../../Entities/EmailEntities/Email';
import {PendingAttachment} from '../../../Entities/EmailEntities/PendingAttachment';
import {PaginateModel} from '../../../Entities/PaginateModel';
import {EmailFileUpdate} from '../../../Entities/EmailFileUpdate';
import {ItemSiteType} from './email-filter.component';
import {EmailFilterModel} from '../../../Entities/EmailFilterModel';
import {ApprovalCriteria} from '../../../Entities/ApprovalCriteria';
import {CriteriaType} from '../../../Entities/FilterCriterion';

@Component({
  selector: 'app-email-filter-view',
  templateUrl: './email-filter-view.component.html',
  styleUrls: ['./email-filter-view.component.css']
})
export class EmailFilterViewComponent implements OnInit, AfterViewInit {
  @Input()
  uncheckedEmails: Email[];
  @Input()
  emailsCount: number;
  @Input()
  isLoadingData: boolean;
  @Input()
  currentPage: number;
  @Input()
  pageSize: number;
  @Input()
  siteType: ItemSiteType;
  @Input()
  previousYScrollPosition: number;
  @Input()
  idOfNewlyAddedCriterionFromModal: number;

  @Output()
  public tryGetEmailsEmitter = new EventEmitter();
  @Output()
  public trySetCurrentPage = new EventEmitter();
  @Output()
  public trySetPageSize = new EventEmitter();


  @Output()
  public trySetEmailToChecked = new EventEmitter();
  @Output()
  public trySetEmailFromChecked = new EventEmitter();

  @Output()
  public tryToggleEmailApproved = new EventEmitter();
  @Output()
  public tryToggleEmailRejected = new EventEmitter();

  @Output()
  public tryToggleFileApproved = new EventEmitter();
  @Output()
  public tryToggleFileRejected = new EventEmitter();

  @Output()
  tryToggleEmailNeutral = new EventEmitter();

  @Output()
  public tryApproveCheckedEmails = new EventEmitter();
  @Output()
  public tryApproveAllEmailsEmitter = new EventEmitter();
  @Output()
  public tryDisapproveCheckedEmails = new EventEmitter();
  @Output()
  public tryApproveAndDisapproveEmails = new EventEmitter();
  @Output()
  public tryShowPendingEmail = new EventEmitter();
  @Output()
  public tryShowPendingAttachment = new EventEmitter();
  @Output()
  public tryShowEmailFilterConfig = new EventEmitter();
  @Output()
  public tryDownloadAttachment = new EventEmitter();

  @Output()
  public tryChangePageSize = new EventEmitter();
  @Output()
  public tryApproveCriteria = new EventEmitter();
  @Output()
  public tryValidateEmails = new EventEmitter();

  lastPageIndex: number;
  isToggle: boolean;
  emailIDToShowAttachmentsFor: number;

  ItemSiteType = ItemSiteType;


  public chosenPageSize: number;

  sender: string;
  criterionToBeCreated: ApprovalCriteria;
  itemFields: any[] = [{value: 'Sender', viewValue: 'Afsender'}, {value: 'Subject', viewValue: 'Emne'}, {
    value: 'BodyText',
    viewValue: 'Hovedtekst'
  }];
  makeNewCriterion: Boolean = false;

  constructor() {
    this.isToggle = false;

  }

  ngOnInit() {
    this.chosenPageSize = this.pageSize;
  }

  ngAfterViewInit(): void {
    if (!!this.previousYScrollPosition) {
      document.documentElement.scrollTo(0, this.previousYScrollPosition - 60);
    }
  }

  tryToValidateEmails() {
    this.tryValidateEmails.emit();
  }
  tryToValidateEmailsWithNewlyAddedCriterion(idOfNewlyAddedCriterion: number) {
    if(!!idOfNewlyAddedCriterion) {
      this.tryValidateEmails.emit(idOfNewlyAddedCriterion);
      this.idOfNewlyAddedCriterionFromModal = undefined;
    }
  }

  makeNewCriterionTrue() {
    if(this.makeNewCriterion != true) {
      this.makeNewCriterion = true;
    } else {
      this.makeNewCriterion = false;
    }
  }

  tryApproveThisCriteria(criterion: ApprovalCriteria) {
    this.tryApproveCriteria.emit(criterion);
    criterion.Value = '';
    criterion.FilterField = '';
  }

  approveAndDisapproveEmails() {
    let emailFilterModel = new EmailFilterModel();

    emailFilterModel.emailsToApprove = this.uncheckedEmails.filter(x => x.IsApproved);
    emailFilterModel.emailsToApprove.forEach(x => x.PendingAttachments = x.PendingAttachments.filter(x => x.IsApproved == true));

    emailFilterModel.emailsToDisapprove = this.uncheckedEmails.filter(x => x.IsRejected);
    emailFilterModel.emailsToDisapprove.forEach(x => x.PendingAttachments = x.PendingAttachments.filter(x => x.IsRejected == true));

    this.tryApproveAndDisapproveEmails.emit(emailFilterModel);
  }

  tryApproveEmails() {
    let emailsToIndex = this.uncheckedEmails.filter(x => x.IsApproved);
    //emailsToIndex.forEach(x => x.PendingAttachments = x.PendingAttachments.filter(x => x.IsApproved == true));
    this.tryApproveCheckedEmails.emit(emailsToIndex);
    this.isToggle = false;
  }

  tryApproveAllEmails() {
    this.tryApproveAllEmailsEmitter.emit();
    this.isToggle = false;
  }

  tryDisapproveEmails() {
    let emailsToIndex = this.uncheckedEmails.filter(x => x.IsRejected);
    emailsToIndex.forEach(x => x.PendingAttachments = x.PendingAttachments.filter(x => x.IsRejected == true));
    this.tryDisapproveCheckedEmails.emit(emailsToIndex);
    this.isToggle = false;
  }

  changePageSize(pageSize: number) {
    this.tryChangePageSize.emit(pageSize);
  }

  //Determines whether or not the email in question is checked.
  isChecked(x: Email) {
    return x.IsChecked;
  }

  //Mathmatics to calculate how many pages the pagination should define.
  getLastPageIndex() {
    return (this.lastPageIndex = Math.ceil(this.emailsCount / this.pageSize));
  }

  //Method to emit pageIndex and pageModel to EmailFilterComponent.
  getEmailForPage(pageIndex: number) {
    if (pageIndex <= this.lastPageIndex && pageIndex > 0) {
      this.trySetCurrentPage.emit(pageIndex);
      let pageModel = new PaginateModel();
      pageModel.PageIndex = pageIndex;
      pageModel.PageSize = this.pageSize;
      this.tryGetEmailsEmitter.emit(pageModel);
    }
    this.isToggle = false;
  }

  //Emits an object of PendingEmail to EmailFilterComponent.
  showPendingEmail(pendingEmail: Email) {
    this.tryShowPendingEmail.emit(pendingEmail);
  }

  //Emits an object of PendingAttachment to EmailFilterComponent.
  showPendingAttachment(pendingAttachment: PendingAttachment) {
    this.tryShowPendingAttachment.emit(pendingAttachment);
  }

  //Sets all emails as checked and emits an array of Email to EmailFilterComponent.
  selectAllMails() {
    if (!this.isToggle) {
      for (let mail of this.uncheckedEmails) {
        this.trySetEmailToChecked.emit(mail);
      }
    } else {
      for (let mail of this.uncheckedEmails) {
        this.trySetEmailFromChecked.emit(mail);
      }
    }
  }

  toggleEmailApproved(email: Email) {
    this.sender = email.EmailContent.Sender;
    this.criterionToBeCreated = ({ID: 0, FilterField: "Sender", Automatic: false, Value: this.sender, Type: CriteriaType.WhiteList});
    this.tryToggleEmailApproved.emit(email);
  }

  toggleEmailRejecetd(email: Email) {
    this.sender = email.EmailContent.Sender;
    this.criterionToBeCreated = ({ID: 0, FilterField: "Sender", Automatic: false, Value: this.sender, Type: CriteriaType.BlackList});
    this.tryToggleEmailRejected.emit(email);
  }

  toggleFileApproved(email: Email, file: PendingAttachment) {
    //if(this.siteType == ItemSiteType.Pending) {
    this.tryToggleFileApproved.emit({emailId: email.ID, fileId: file.ID});
    //}
  }

  toggleFileRejecetd(email: Email, file: PendingAttachment) {
    this.tryToggleFileRejected.emit({emailId: email.ID, fileId: file.ID});
  }

  toggleEmailNeutral(email: Email) {
    this.tryToggleEmailNeutral.emit(email);
  }

  //Unchecks all emails checked and emits Email to EmailFilterComponent.
  reverseEmailChecked(email: Email) {
    console.log(email);


    /*if (email.IsChecked) {
      this.isToggle = false;
      this.trySetEmailFromChecked.emit(email);
    } else {
      this.trySetEmailToChecked.emit(email);
      if (
        this.uncheckedEmails.filter(this.isChecked).length ==
        this.uncheckedEmails.length
      )
        this.isToggle = true;
    }*/
  }


  showEmailFilterConfig() {
    this.tryShowEmailFilterConfig.emit();
  }

  showAttachments(ID: number) {
    if (this.emailIDToShowAttachmentsFor != ID) {
      this.emailIDToShowAttachmentsFor = ID;
    } else {
      this.emailIDToShowAttachmentsFor = -1;
    }
  }

  resetCriterionIdFromModal() {
    this.idOfNewlyAddedCriterionFromModal = undefined;
  }

  downloadAttachment(file: PendingAttachment) {
    this.tryDownloadAttachment.emit(file);
  }
}
