import { Email } from "./EmailEntities/Email";
import { ApprovedAttachment } from "./EmailEntities/ApprovedAttachment";
import { SearchCriteria } from "./SearchCriteria";
import { UserTheme } from "./UserTheme";
import {User} from './User';
export class Theme {
  ID?: number;
  SearchCriterias?: SearchCriteria[];
  Title?: string = "";
  CreatedDate?: Date;
  Emails?: Email[];
  ApprovedAttachments?: ApprovedAttachment[];
  UserThemes?: UserTheme[];
  EmailCount?: number;
  AttachmentCount?: number;
  SearchCriteriaCount?: number = 0;

  // 221019 nm
  IsSubscribed?: boolean = false;
  HasCreated?: boolean = false;
  Creators?: User[];
}
