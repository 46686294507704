import { Component, OnInit } from "@angular/core";
import { DashboardModel } from "../../Entities/DashboardModel";
import { DashboardService } from "../../services/dashboard.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"]
})
export class DashboardComponent implements OnInit {
  dashboardData: DashboardModel;
  barChartData: any[];

  constructor(private dashboardService: DashboardService) {}

  ngOnInit() {
    this.getDashboardData();
  }

  //Method to get response from the database regarding dashboard data through a subscription to getDashboardData() in DashboardService.
  //Returns data showing the matching level of emails and search criteria.
  getDashboardData () {
    this.dashboardService.getDashboardData().subscribe(dashData => {
      (this.dashboardData = dashData),
        (this.barChartData = [
          {
            data: [
              this.dashboardData.MailStatusChart.HighlyIndexMails,
              this.dashboardData.MailStatusChart.MediumIndexMails,
              this.dashboardData.MailStatusChart.LowIndexMails
            ],
            label: "Status"
          }
        ]);
    });
  }
}
