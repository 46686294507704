import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GDPRSearchCriteria} from '../../Entities/GDPRSearchCriteria';
import {GDPRLog} from '../../Entities/GDPRLog';
import {GDPRSearchCriteriaService} from '../../services/gdprsearch-criteria.service';
import {GDPRLogService} from '../../services/gdprlog.service';
import {ApprovedItem} from '../../Entities/EmailEntities/ApprovedItem';
import {PaginateModel} from '../../Entities/PaginateModel';
import {GdprLogPageModel} from '../../Entities/GdprLogPageModel';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gdpr-view',
  templateUrl: './gdpr-view.component.html',
  styleUrls: ['./gdpr-view.component.css']
})
export class GDPRViewComponent implements OnInit {
  @Input()
  gdprSearchCriterias: GDPRSearchCriteria[];
  @Input()
  unhandledScanGDPRLogs: GDPRLog[];
  @Input()
  gdprLogs: GDPRLog[];
  @Input()
  pageSize: number;
  @Input()
  currentPage: number;
  @Input()
  gdprLogCount: number;

  @Output()
  public tryShowGdprLogs = new EventEmitter();
  @Output()
  public tryDeleteEmitter = new EventEmitter();
  @Output()
  public tryDeleteGDPRLogs = new EventEmitter();
  @Output()
  public tryUpdateGdprLog = new EventEmitter();
  @Output()
  public tryHandleGdprLogs = new EventEmitter();
  @Output()
  public trySetCurrentPage = new EventEmitter();
  @Output()
  public tryGetGdprLogs = new EventEmitter();
  @Output()
  public tryScan = new EventEmitter();
  handlingType: string;
  lastPageIndex: number;
  isToggle: boolean;
  gdprSearchCriteria: GDPRSearchCriteria;
  constructor(private gdprSearchCriteriaService : GDPRSearchCriteriaService,
              private gdprLogServce : GDPRLogService,
              private router : Router)
  {
    this.gdprSearchCriteria = new GDPRSearchCriteria;
  }

  ngOnInit() {
  }
  showGdprLog() {
    this.tryShowGdprLogs.emit();
  }
  CreateGDPRCriteria()
  {
    this.gdprSearchCriteria.GDPRCriteria = true;
    this.gdprSearchCriteriaService.create(this.gdprSearchCriteria).subscribe(result =>
        {console.log(result),
        this.gdprSearchCriteriaService.getAll().subscribe(result => {this.gdprSearchCriterias = result});
      });
  }
  scan() {
    this.tryScan.emit();
  }
  trydeleteGDPRCriteria(gdprSearchCriterias: GDPRSearchCriteria) {
    if (confirm('Er du sikker på du vil slette ' + gdprSearchCriterias.Criteria + ' fra systemet?')) {
      this.tryDeleteEmitter.emit(gdprSearchCriterias);
    } else {
      // Do nothing!
    }
  }
  deleteGDPRLogs() {
    if (confirm('Er du sikker på du vil slette alle GDPR-Logs fra systemet?')) {
      this.tryDeleteGDPRLogs.emit();
    } else {
      // Do nothing!
    }
  }
  handleGDPRLogs(pageIndex: number) {
    if (confirm('Er du sikker på du vil håndtere alle GDPR-Logs ud fra opsætningen?')) {
      console.log(pageIndex);
      console.log(this.lastPageIndex);
      if (pageIndex <= this.lastPageIndex && pageIndex > 0) {
        this.trySetCurrentPage.emit(pageIndex);


        let pageModel = new GdprLogPageModel();
        pageModel.PageIndex = pageIndex;
        pageModel.PageSize = this.pageSize;
        console.log(pageModel);
        this.tryHandleGdprLogs.emit(pageModel);
      }
    } else {
      // Do nothing!
    }
  }
  showEntityDetails(log : GDPRLog)
  {
    console.log("HIT!");
    switch(log.EntityName) {
      case 'ApprovedFile':
      {

      }
      case 'ApprovedEmail': {

      }
    }

    this.router.navigate(["/emails/" + log.EntityId + "/" + log.EntityName + "/" + "gdpr"])
  }

  changeHandlingType(log: GDPRLog) {
    this.tryUpdateGdprLog.emit(log);
  }
  getGdprLogsForPage(pageIndex: number) {
    if (pageIndex <= this.lastPageIndex && pageIndex > 0) {
      this.trySetCurrentPage.emit(pageIndex);


      let pageModel = new GdprLogPageModel();
      pageModel.PageIndex = pageIndex;
      pageModel.PageSize = this.pageSize;
      this.tryGetGdprLogs.emit(pageModel);
    }
  }
  getLastPageIndex() {
    return (this.lastPageIndex = Math.ceil(this.gdprLogCount / this.pageSize));
  }
}
