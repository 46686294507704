import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ApprovedAttachment } from "../../../Entities/EmailEntities/ApprovedAttachment";
import { FileHistory} from '../../../Entities/FileHistory';
import {LoginService} from '../../../services/login.service';
import { DomSanitizer } from '@angular/platform-browser';
import {Router} from '@angular/router';
import {run} from "tslint/lib/runner";

export enum ItemType {
  ApprovedFile = "Approved",
  PendingFile = "Pending",
  RejectedFile = "Rejected"
}


@Component({
  selector: "app-approved-attachment-detail-view",
  templateUrl: "./approved-attachment-detail-view.component.html",
  styleUrls: ["./approved-attachment-detail-view.component.css"]
})
export class ApprovedAttachmentDetailViewComponent implements OnInit {
  public ItemType = ItemType;
  public ItemTypeToShow : ItemType;

  file: ApprovedAttachment;
  _fileHistories : FileHistory[];

  fileUserID : any;
  @Input()
  returnUrl : any;

  @Input()
  downloadURL : string;

  @Output()
  public tryGetDownloadURLEmitter = new EventEmitter();


  @Input()
  set approvedFile(file : ApprovedAttachment)
  {
    this.file = file;
    this.setFileUserID();
    this.ItemTypeToShow = ItemType.ApprovedFile;
  }

  @Input()
  set pendingFile(file : ApprovedAttachment)
  {
    this.file = file;
    this.setFileUserID();
    this.fileUserID = file.email != null ? file.email.User.ID : file.user.ID;
    this.ItemTypeToShow = ItemType.PendingFile;
  }

  @Input()
  set rejectedFile(file : ApprovedAttachment)
  {
    this.file = file;
    this.setFileUserID();
    this.fileUserID = file.email != null ? file.email.User.ID : file.user.ID;
    this.ItemTypeToShow = ItemType.RejectedFile;
  }

  @Input()
  set fileHistories(fileHistories : FileHistory[])
  {
    if (fileHistories != null) {
      this._fileHistories = fileHistories;
    }
  }

  @Input()
  fileUrl: string;
  @Output()
  public tryGetFileUrl = new EventEmitter();


  @Input()
  pdfToOpenUrl : string;

  @Input()
  base64Item : string;
  @Output()
  public tryGetBase64Item = new EventEmitter();

  @Output()
  public tryToOpenPDFFileEmitter = new EventEmitter();

  @Output()
  public tryBackEmitter = new EventEmitter();
  @Output()
  public tryDownloadEmitter = new EventEmitter();
  @Output()
  public tryRejectFile = new EventEmitter();
  public loggedInUserID : string;
  public isLoggedUserAdmin: boolean;
  public canBeOpened: boolean;
  public isTxtFile: boolean;

  //NM 240120 manual indexing data on files >>
  @Output ()
  public tryUpdateManualIndexingData = new EventEmitter();

  public manualIndexingDataInputSelected: boolean;
  //NM 240120 manual indexing data on files >>

  constructor(private loginService : LoginService, private router : Router, private _sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    //this.tryGetDownloadURLEmitter.emit(this.file.ID);
    this.loggedInUserID = this.loginService.getLoggedInUserID();
    this.isLoggedUserAdmin = this.loginService.isAdminLoggedIn()
    this.canBeOpened = this.file.email == null;
    this.getBase64();
    if (!!this.file.ServiceId) {
      this.tryGetFileUrl.emit(this.file.ID);
    }

    console.log(this.base64Item);
    // NM 230120 - Delete search with ESC/Escape >>
    let self = this;
    window.addEventListener("keydown", function (event) {
      switch (event.key) {
        case "Esc": // IE/Edge specific value
          self.back();
          break;
        case "Escape":
          // Do something for "esc" key press.
          self.back();
          break;
      }
    });
    // NM 230120 - Delete search with ESC/Escape <<
  }

  //emits an action event to EmailControllerComponent.
  back() {
    if(!this.returnUrl) {
      this.tryBackEmitter.emit();
    }
    else{
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  //emits an action event to EmailControllerComponent.
  download() {
    this.tryDownloadEmitter.emit();
  }

  setFileUserID()
  {
    this.fileUserID = null;

    if(this.file.email != null)
    {
      this.fileUserID = this.file.email.User.ID
    }
    else{
      if(this.file.user != null)
      this.fileUserID = this.file.user.ID;
    }

  }
  getBase64() {
    switch (this.file.FileType.toLowerCase()) {
      case 'jpg':
      case '.jpg' :
      case 'png':
      case '.png' :
      case 'jpeg' :
      case '.jpeg' :
        this.tryGetBase64Item.emit(this.file.ID);
        break;
    }
  }

  rejectFile()
  {
    if (confirm('Er du sikker på at du vil afvise denne fil? \r\n \r\n Filen kan herefter findes under afviste filer')) {
      this.tryRejectFile.emit(this.file);
    } else {
      // Do nothing!
    }
  }
  getFilePath(type: string) {
      const formattedFilePath = this.file.FileName.replace(/\\/g, '/');
      let fileType = 'No file type';
      switch (this.file.FileType) {
        case 'xls':
        case 'xlsx':
        case '.xlsx':
        case 'csv':
        case '.csv':
          fileType = 'ms-excel';
          break;
        case 'doc':
        case 'docx':
        case '.docx':
          fileType = 'ms-word';
          break;
        case 'ppt':
        case 'pptx':
        case '.pptx':
          fileType = 'ms-powerpoint';
          break;
        case 'vsd':
        case 'vsdx':
        case '.vsdx':
          fileType = 'ms-visio';
          break;
        case 'pdf':
        case '.pdf':
          //return simple string
          return 'pdf';
          break;
        case 'txt':
          return 'txt';
          break;
        case 'xml':
            return 'xml';
        default:
          if(!!!this.file.ServiceId) {
            alert('Filtype ikke understøttet');
          }
          return '';
          break;
      };
      return fileType + ':' + type + '|u|file:/' + formattedFilePath;
  }

  showFile() {
    const filePath = this.getFilePath('ofv');

    if (!!this.file.ServiceId && filePath !== 'pdf') {
      if(!!this.fileUrl) {
        window.open(this.fileUrl, '_blank');
      }
    }
    else if(filePath === 'pdf' || !!!this.file.ServiceId) {

      switch (filePath) {
        case 'pdf':
          //open pdf file (email-controller.component)
          this.tryToOpenPDFFileEmitter.emit(this.file);
          break;
        case '':
          break;
        case 'txt':
        case 'xml':
          // open txt file
          this.isTxtFile = true;
          this.tryGetBase64Item.emit(this.file.ID);
          //window.open(this.file.FileName, '_self');
          break;
        default:
          window.open(filePath, '_self');
          break;
      }
    }
  }

  editFile() {
    window.open(this.getFilePath('ofe'), '_self');
  }

  //NM 240120 manual indexing data on files >>
  manualIndexingDataInputChanged() {
    this.manualIndexingDataInputSelected = true; //!this.manualIndexingDataInputSelected;
  }
  //NM 240120 manual indexing data on files <<

  //NM 240120 manual indexing data on files >>
  updateManualIndexingData(file: ApprovedAttachment) {
    this.tryUpdateManualIndexingData.emit(file);
    this.manualIndexingDataInputSelected = false;
  }
  //NM 240120 manual indexing data on files <<
  convertBase64ToText(base64Text: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl('data:text/plain;base64,'
      + base64Text);
  }
}
