import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ErrorLog} from '../../../Entities/ErrorLog';
import {ErrorLogFilter} from '../../../Entities/ErrorLogFilter';
import {User} from '../../../Entities/User';

@Component({
  selector: 'app-error-log-view',
  templateUrl: './error-log-view.component.html',
  styleUrls: ['./error-log-view.component.css']
})
export class ErrorLogViewComponent implements OnInit {

  pickedDate;
  pageNumber = 1;
  errorLogFilters: ErrorLogFilter;
  @Input()
  allUsers: User[];
  @Input()
  allPlatforms: string[];
  @Input()
  errorLogs: ErrorLog[];
  @Input()
  totalPageNumber;
  @Output()
  public tryGetFilteredErrorLogs = new EventEmitter();
  browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor() {}

  ngOnInit() {
    this.errorLogFilters = new ErrorLogFilter();
    this.GetFilteredErrorLogs();
    this.errorLogFilters.PageNumber = this.pageNumber;
  }

  GetNextPage(){
    if(this.errorLogFilters.PageNumber < this.totalPageNumber)
    {
      this.errorLogFilters.PageNumber ++;
      this.tryGetFilteredErrorLogs.emit(this.errorLogFilters)
    }
  }

  GetPreviousPage(){
    if(this.errorLogFilters.PageNumber > 1){
      this.errorLogFilters.PageNumber --;
      this.tryGetFilteredErrorLogs.emit(this.errorLogFilters)
    }
  }

  GetFilteredErrorLogs(){
    this.errorLogFilters.PageNumber = 1;
    this.errorLogFilters.PageNumber = this.pageNumber;
    this.tryGetFilteredErrorLogs.emit(this.errorLogFilters)
  }

  FilterDateChange(pickedDate){
    this.errorLogFilters.PageNumber = 1;
    this.errorLogFilters.DateTime = new Date(pickedDate.value);
    this.tryGetFilteredErrorLogs.emit(this.errorLogFilters);
  }

  FilterPlatformChange(){
    this.errorLogFilters.PageNumber = 1;
    this.tryGetFilteredErrorLogs.emit(this.errorLogFilters);
  }

  resetDate(){
    this.errorLogFilters.PageNumber = 1;
    this.errorLogFilters.DateTime= null;
    this.tryGetFilteredErrorLogs.emit(this.errorLogFilters);
  }
}
