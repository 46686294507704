import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmailAccount, EmailType} from '../../../Entities/EmailAccount';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../Entities/User';
import {Email} from '../../../Entities/EmailEntities/Email';
import {ActivatedRoute} from '@angular/router';
declare var gapi: any;
declare var require: any;

var ServiceConfiguration = require('assets/config.json');

@Component({
  selector: "app-email-account-create-view",
  templateUrl: "./email-account-create-view.component.html",
  styleUrls: ["./email-account-create-view.component.css"]
})
export class EmailAccountCreateViewComponent implements OnInit {

  emailAccount: EmailAccount;

  @Input()
  userIdFromRoute: string;

  @Input()
  user: User;

  @Input()
  errorMessage: string;

  emailType : EmailType;

  form: FormGroup;

  @Output()
  public tryAddEmitter = new EventEmitter();

  @Output()
  public tryLogInWithGoogle = new EventEmitter();

  EmailTypeGmail : EmailType.Gmail;


  constructor( private formBuilder: FormBuilder, private route : ActivatedRoute) {}

  ngOnInit() {
    this.emailAccount = new EmailAccount();
    this.emailAccount.User = new User();

    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null],
      type: [null, Validators.required],
    });

    this.formControlValueChanged();
    this.createAccountIfCodeInUrl();
  }


  formControlValueChanged() {
    const phoneControl = this.form.get('password');
    this.form.get('type').valueChanges.subscribe(
      (mode: number) => {
        console.log(mode);
        if (mode === EmailType.Exchange) {
          //change
          phoneControl.clearValidators();
          const passwordValidation = this.form.get('email');
          passwordValidation.clearValidators();
          passwordValidation.updateValueAndValidity();
          //phoneControl.setValidators([Validators.required]);
        }
        else if (mode === EmailType.Gmail) {
          const passwordValidation = this.form.get('email');
          passwordValidation.clearValidators();
          passwordValidation.updateValueAndValidity();
        }
        phoneControl.updateValueAndValidity();
      });
  }

  //Method to emit an emailAccount object to EmailAccountCreateComponent.
  tryAddEmailAccount() {
    switch (this.emailType) {
      case EmailType.Gmail:
        this.emailAccount.EmailType = EmailType.Gmail;
        console.log(this.emailAccount);
        this.tryLogInWithGoogle.emit(this.emailAccount);
        break;
      case EmailType.Exchange:
        this.emailAccount.EmailType = EmailType.Exchange;
        let clientId = ServiceConfiguration['graphServiceClientID'];
        let redirectUri = location.origin + '/emailaccount-create/';
        window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+ clientId +'&redirect_uri='+ redirectUri +'&response_type=code&scope=user.read%20mail.read%20offline_access&prompt=select_account&state=12345';
        break;
    }
  }

  createAccountIfCodeInUrl() {
    this.route.queryParams.subscribe(params => {
      let code = params['code'];
      if (!!code) {
        this.emailAccount.EmailType = EmailType.Exchange;
        this.emailAccount.RefreshToken = code;
        this.tryAddEmitter.emit(this.emailAccount);
      }
    });
  }
}
