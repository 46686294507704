import { Injectable } from "@angular/core";
import { Headers } from "@angular/http";
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class ConnectionService {
   // 'https://collaborator_test_api_2.eliteit.dk/';
   // 'https://collaborator_test_api_2.eliteit.dk/'
   //  private apiUrl = 'https://collaborator_vinkel_api.eliteit.dk/';
   //  private apiUrl = 'https://apicollaborator.eliteit.dk/';
   //  private apiUrl = 'http://localhost:7985/';
     private apiUrl = 'https://collaborator_test_api.eliteit.dk/'
   //  private apiUrl = 'https://collaborator_vesthimmerland_api.eliteit.dk/';

  private headers: Headers;
  private headersClient: HttpHeaders;

  constructor() {}

  GetConnectionUrl() {
    return this.apiUrl;
  }

  GetAuthorizationHeader() {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    this.headers.append(
      "Authorization",
      "Bearer " + sessionStorage.getItem("token")
    );
    return this.headers;
  }

  GetAuthorizationHeaderClient() {
    this.headersClient = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    });
    return this.headersClient;
  }

}

