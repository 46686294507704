import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { ConnectionService } from "./connection.service";
import { ThemeRequest } from "../Entities/ThemeRequest";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Theme } from "../Entities/Theme";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SubscriptionService {
  private apiUrl;

  constructor(
    //private http: Http,
    private httpClient : HttpClient,
    private connectionService: ConnectionService
  ) {
    this.apiUrl =
      this.connectionService.GetConnectionUrl() + "api/Subscription";
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  addSubscription(Theme: Theme, userID: number): Observable<boolean> {
    var dataModel = new ThemeRequest();
    dataModel.Theme = Theme;
    dataModel.UserID = userID;
    const url = `${this.apiUrl}/AddSubscription/`;
    return this.httpClient
      .post<boolean>(url, JSON.stringify(dataModel), { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as boolean));
  }

  deleteSubscription(Theme: Theme, userID: number): Observable<boolean> {
    var dataModel = new ThemeRequest();
    dataModel.Theme = Theme;
    dataModel.UserID = userID;
    const url = `${this.apiUrl}/DeleteSubscription/`;
    return this.httpClient
      .put<boolean>(url, JSON.stringify(dataModel), { headers: this.getAuthenticationHeader() })
      //.pipe(map(response => response.json() as boolean));
  }
}
