import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {User} from '../../../Entities/User';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {
  errorMessage: string;
  user: User;
  userForm: FormGroup;

  constructor(private userService: UserService,
              private router: Router,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.user = new User();
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  createUserAccount(user: User) {
    if (user != null) {
      user.IsAdmin = false;
      user.GDPRAdmin = false;
      this.userService
        .createUser(user)
        .subscribe(() => {
            this.snackBar.open('Bruger: ' + user.EmailAdress + ' er nu oprettet', 'OK', {
            duration: 3000
          });
          this.router.navigate(['/users'])},
          err =>
            (this.errorMessage =
              'Brugeren kunne ikke oprettes i systemet. Prøv igen.')
        );
    }
  }
}
