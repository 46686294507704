import { Component, OnInit } from '@angular/core';
import {GDPRLogService} from '../../services/gdprlog.service';
import {GDPRLog} from '../../Entities/GDPRLog';
import {GDPRSearchCriteriaService} from '../../services/gdprsearch-criteria.service';
import {GDPRSearchCriteria} from '../../Entities/GDPRSearchCriteria';
import {GdprLogPageModel} from '../../Entities/GdprLogPageModel';
import {PaginateModel} from '../../Entities/PaginateModel';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.css']
})
export class GDPRComponent implements OnInit {

  showLog: boolean;
  gdprLogs: GDPRLog[];
  gdprSearchCriterias: GDPRSearchCriteria[];
  unhandledScanGDPRLogs: GDPRLog[];
  pageSize: number;
  currentPage: number;
  gdprLogCount: number;

  constructor(private gdprLogService : GDPRLogService,
              private  gdprSearchCriteriaService : GDPRSearchCriteriaService
  )
  {
    this.gdprSearchCriterias = [];
    this.gdprLogs = [];
    this.unhandledScanGDPRLogs = [];
    this.gdprSearchCriteriaService.getAll().subscribe(result => {this.gdprSearchCriterias = result});
    this.gdprLogService.getHandledScanGDPRLogs().subscribe(result => {this.gdprLogs = result});
    //this.gdprLogService.getUnhandledScanGDPRLogs().subscribe(result => {this.unhandledScanGDPRLogs = result});
  }

  ngOnInit() {
    this.pageSize = 10;
    this.currentPage = 1;
   this.getPaginatedData()
  }
  getPaginatedData() {
    if (this.pageSize == 0) {
      this.pageSize = 10;
    }
    let pageModel = new GdprLogPageModel();
    pageModel.PageIndex = this.currentPage;
    pageModel.PageSize = this.pageSize;
    this.getUnhandledGdprLogsPaginated(pageModel);
  }
  showGdprLogs() {
    this.gdprLogService.getHandledScanGDPRLogs().subscribe(result => this.gdprLogs = result);
    this.showLog = true;
  }
  backToGDPRView() {
    this.showLog = false;
  }
  deleteGdprSearchCriteria(gdprSearchCriteria: GDPRSearchCriteria) {
    this.gdprSearchCriteriaService.delete(gdprSearchCriteria).subscribe(result => {
      this.gdprSearchCriteriaService.getAll().subscribe(result => { this.gdprSearchCriterias = result})
    })
  }
  deleteGdprLogs() {
    this.gdprLogService.deleteAll().subscribe(result => {this.gdprLogs = result
      this.gdprLogService.getUnhandledScanGDPRLogs().subscribe(result2 => { this.unhandledScanGDPRLogs = result2})
    })
  }
  updateGdprLog(log: GDPRLog) {
    this.gdprLogService.update(log).subscribe(result => {
      this.gdprLogService.getUnhandledScanGDPRLogs().subscribe(result => this.getPaginatedData())
    });
  }
  handleGdprLogs(pageModel: GdprLogPageModel) {
    console.log("Handling");
    this.gdprLogService.handleGdprLogs(pageModel).subscribe(result =>  (this.unhandledScanGDPRLogs = result.GDPRLogs) && (this.gdprLogCount = result.Total) && (console.log(result)));
  }
  setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;

  }
  getUnhandledGdprLogsPaginated(gdprPageModel: GdprLogPageModel) {
    this.gdprLogService.getPaginated(gdprPageModel).subscribe(result => (this.unhandledScanGDPRLogs = result.GDPRLogs) && (this.gdprLogCount = result.Total) && (console.log(result)));
  }
  scan() {
    this.currentPage = 1;
    this.gdprLogService.scanForGDPR().subscribe(result => this.getPaginatedData());
  }

}
