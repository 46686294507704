import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import {EmailAccount} from '../../../Entities/EmailAccount';
import {ActivatedRoute, Router} from '@angular/router';
import {EmailAccountService} from '../../../services/email-account.service';
import {LoginService} from '../../../services/login.service';
import {MatSnackBar} from '@angular/material';
declare var gapi: any;
declare var require: any;

var ServiceConfiguration = require('assets/config.json');

@Component({
  selector: 'app-email-account-detail',
  templateUrl: './email-account-detail.component.html',
  styleUrls: ['./email-account-detail.component.css']
})
export class EmailAccountDetailComponent implements OnInit, AfterViewInit {

  emailAccount: EmailAccount;
  emailAccountIdFromRoute: string;
  userId: string;
  errorMessage: string;

  loading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private emailAccountService: EmailAccountService,
    private loginService: LoginService,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngZone : NgZone,) {
  }

  //Gets a specific emailAccount by parsing params into the parameter of the get() method in EmailAccountService and gets a response through a subscription.
  //The response defines the value of instance variable emailAccount.
  getEmailAccount() {
    this.emailAccount = new EmailAccount();
    this.route.params.subscribe(params => {
      if (params["id"]) {
        this.emailAccountIdFromRoute = (params['id']);
      } else {
        this.emailAccountIdFromRoute =
          !!this.loginService.getLastVisitedEmailAccount() && this.loginService.getLastVisitedEmailAccount() != "" ?
          this.loginService.getLastVisitedEmailAccount() : this.loginService.getLastVisitedEmailAccount();

      }
      this.loginService.setLastVisitedEmailAccount(this.emailAccountIdFromRoute);
      this.emailAccountService
        .get(this.emailAccountIdFromRoute)
        .subscribe(account => {
          this.userId = account.User.ID + "";
          if (this.loginService.getLoggedInUserID() == this.userId || this.loginService.isAdminLoggedIn()) {
            this.emailAccount = account;
          } else {
            this.router.navigate(['/']);
            this.snackBar.open('Du er ikke autoriseret til at se denne side', 'OK', {
              duration: 3000
            });
          }
        })
    });
  }

  ngOnInit() {
    this.getEmailAccount();
  }

  //used to update Gmail account it overwrites the current email in the database with the value parsed as parameter into this method.
  //When the email is overwritten, the user is routed to /emailaccounts.
  saveEmailAccount(account: EmailAccount) {
    this.loading = true;
    console.log(account);
    if (this.emailAccountIdFromRoute) {
      account.ID = parseInt(this.emailAccountIdFromRoute);
    }
    this.emailAccountService
      .update(account)
      .subscribe(account => {this.ngZone.run(() => {
        this.loading = false;
          this.snackBar.open('Email konto: ' + account.EmailAddress + ' er nu opdateret', 'OK', {
            duration: 3000
          });
        this.router.navigate(['/user-detail/' + this.loginService.getLastVisitedUser()])
    }, 5); },
    err =>
      (
        this.loading = false,
        this.errorMessage = err.error
        )
      );
    //);
  }

  updateGmailAccount(emailAccount) {
    const auth3 = gapi.auth2.getAuthInstance();
    auth3.grantOfflineAccess().then(authCode => {
      emailAccount.RefreshToken = authCode.code;
      console.log(emailAccount.RefreshToken);
      if (!!emailAccount.RefreshToken) {
        console.log(emailAccount);
        this.saveEmailAccount(emailAccount);
      }
    });
  }

  public googleInit() {
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: ServiceConfiguration['gmailServiceClientID'],
        fetch_basic_profile: false,
        scope: 'https://www.googleapis.com/auth/gmail.readonly'
      });
    });
  }

  ngAfterViewInit(): void {
    this.googleInit();
  }

}

