import { Injectable } from "@angular/core";
import { Theme } from "../Entities/Theme";
import { Observable } from "rxjs";
import { Http } from "@angular/http";
import { map } from "rxjs/operators";
import { ConnectionService } from "./connection.service";
import { BarChartData } from "../Entities/BarChartData";
import { PaginateModel } from "../Entities/PaginateModel";
import { ThemeRequest } from "../Entities/ThemeRequest";
import { ThemePaginateModel } from "../Entities/ThemePaginateModel";
import { LoginService } from "./login.service";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ThemeService {
  private apiUrl;

  constructor(
    //private http: Http,
    private httpClient : HttpClient,
    private connectionService: ConnectionService,
    private loginService: LoginService
  ) {
    this.apiUrl = this.connectionService.GetConnectionUrl() + "api/themes";
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  create(theme: Theme): Observable<Theme> {
    let themeRequest = new ThemeRequest();
    themeRequest.Theme = theme;
    themeRequest.UserID = Number(this.loginService.getLoggedInUserID());

    return this.httpClient
      .post<Theme>(this.apiUrl, JSON.stringify(themeRequest), { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as Theme));
  }

  //return all topics from the database. Can be called even if a user is not authenticated.
  getAll(): Observable<Theme[]> {
    return this.httpClient
      .get<Theme[]>(this.apiUrl, { headers: this.getAuthenticationHeader() })
      //.pipe(map(response => response.json() as Theme[]));
  }

  delete(theme: Theme): Observable<Theme> {
    return this.httpClient
      .delete<Theme>(this.apiUrl + "/" + theme.ID, { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as Theme));
  }

  //returns a Email with matching id from the database, if it exists. Else writes an error in the browsers console.
  get(id: number): Observable<Theme> {
    return this.httpClient
      .get<Theme>(this.apiUrl + "/" + id, { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as Theme));
  }

  //Updates the Email in the database with matching id if any. Else writes an error in the browsers console.
  update(theme: Theme): Observable<Theme> {
    const url = `${this.apiUrl}/${theme.ID}`;
    return this.httpClient
      .put<Theme>(url, JSON.stringify(theme), { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as Theme));
  }

  //Writes an error message in the browsers console.
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getThemeWithChart(pageModel: PaginateModel): Observable<PaginateModel> {
    pageModel.Theme.Emails = [];
    pageModel.Theme.SearchCriterias = [];
    pageModel.Emails = [];
    pageModel.Total = 0;

    return this.httpClient
      .post<PaginateModel>(this.apiUrl + "/GetThemeData", JSON.stringify(pageModel), {
        headers: this.getAuthenticationHeader()
      });
      //.pipe(map(response => response.json() as PaginateModel));
  }

  getItemsInThemeForClickedMonthPaged(pageModel: PaginateModel): Observable<PaginateModel> {
    pageModel.Theme.Emails = [];
    pageModel.Total = 0;

    return this.httpClient
      .post<PaginateModel>(this.apiUrl + '/GetItemsInThemeForClickedMonthPaged', JSON.stringify(pageModel), {
        headers: this.getAuthenticationHeader()
      });
      //.pipe(map(response => response.json() as PaginateModel));
  }

  /*getChartDataForTheme(ID: number, year: number, searchedInput: string) {
    const url = this.connectionService.GetConnectionUrl() + 'api/chart';

    let pageModel = new PaginateModel();
    pageModel.ThemeId = ID;
    pageModel.Year = year;
    pageModel.SearchInput = searchedInput;
    return this.httpClient
      .post<BarChartData>(url, JSON.stringify(pageModel),{
        headers: this.getAuthenticationHeader()
      });


    //let url = this.connectionService.GetConnectionUrl() + "api/chart";

    //return this.httpClient
    //  .get<BarChartData>(url + "/" + ID + '/' + year + '/' + searchedInput + '/', { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as BarChartData));
  }*/

  getThemesForPage(pageModel: ThemePaginateModel): Observable<ThemePaginateModel> {
    pageModel.Themes = [];
    pageModel.Total = 0;
    pageModel.UserID = Number(this.loginService.getLoggedInUserID());

    return this.httpClient
      .post<ThemePaginateModel>(this.apiUrl + "/GetThemesForPage", JSON.stringify(pageModel), {
        headers: this.getAuthenticationHeader()
      });
      //.pipe(map(response => response.json() as ThemePaginateModel));
  }

  getDistinctYearsForApprovedItemsInTheme(themeId: number, searchedInput : string): Observable<number[]> {
    let pageModel = new PaginateModel();
    pageModel.SearchInput = searchedInput;
    pageModel.ThemeId = themeId;

    //KG 100919 new http request >>
    return this.httpClient
      .post<number[]>(this.apiUrl + '/GetDistinctYears/', JSON.stringify(pageModel),
    {
      headers: this.getAuthenticationHeader()
    });

    //return this.httpClient
    //  .get<number[]>(this.apiUrl + '/GetDistinctYears/' + themeId + "/" + searchedInput, {
    //    headers: this.getAuthenticationHeader()
    //  });
    //KG 100919 <<
      //.pipe(map(response => response.json() as number[]));
  }


  UpdateOrCreateThemeRelation(themeId: number, userId: number) : Observable<any>
  {
    const url = `${this.apiUrl}/UpdateOrCreateThemeRelation/${themeId}/${userId}`;
    return this.httpClient
      .put<Theme>(url, "",{ headers: this.getAuthenticationHeader() });

  }
}

