import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EmailAccount } from "../Entities/EmailAccount";
import { ConnectionService } from "./connection.service";
import {HttpClient} from '@angular/common/http';
import {Email} from '../Entities/EmailEntities/Email';

@Injectable()
export class EmailAccountService {
  private apiUrl;

  constructor(
    //private http: Http,
    private httpClient : HttpClient,
    private connectionService: ConnectionService
  ) {
    this.apiUrl =
      this.connectionService.GetConnectionUrl() + "api/EmailAccounts";
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  create(emailAccount: EmailAccount): Observable<EmailAccount> {
    return this.httpClient
      .post<EmailAccount>(this.apiUrl + "/PostEmailAccountBasedOnType", JSON.stringify(emailAccount), { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as EmailAccount));
  }

  //return all topics from the database. Can be called even if a user is not authenticated.
  getAll(): Observable<EmailAccount[]> {
    return this.httpClient
      .get<EmailAccount[]>(this.apiUrl, { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as EmailAccount[]));
  }

  delete(emailAccount: EmailAccount): Observable<EmailAccount> {
    return this.httpClient
      .delete<EmailAccount>(this.apiUrl + "/" + emailAccount.ID, { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as EmailAccount));
  }

  //returns a Email with matching id from the database, if it exists. Else writes an error in the browsers console.
  get(id: string): Observable<EmailAccount> {
    return this.httpClient
      .get<EmailAccount>(this.apiUrl + "/" + id, { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as EmailAccount));
  }

  getByUserID(id: any): Observable<EmailAccount[]> {
    return this.httpClient
      .get<EmailAccount[]>(this.apiUrl + '/GetByUser/' + id, { headers: this.getAuthenticationHeader() });
  }


  //Updates the Email in the database with matching id if any. Else writes an error in the browsers console.
  update(emailAccount: EmailAccount): Observable<EmailAccount> {
    return this.httpClient
      .put<EmailAccount>(this.apiUrl + "/" + emailAccount.ID, JSON.stringify(emailAccount), {
        headers: this.getAuthenticationHeader()
      });
      //.pipe(map(response => response.json() as EmailAccount));
  }

  updateIsExportingStatus(emailAccount: EmailAccount): Observable<EmailAccount> {
    return this.httpClient
      .put<EmailAccount>(this.apiUrl + '/ChangeIsExportingAttStatus/' + emailAccount.ID, JSON.stringify(emailAccount), {
        headers: this.getAuthenticationHeader()
      });
    //.pipe(map(response => response.json() as EmailAccount));
  }

  getUserByUsername(user: EmailAccount): Observable<EmailAccount> {
    return this.httpClient
      .get<EmailAccount>(this.apiUrl + "?email=" + user.EmailAddress);
      //.pipe(map(response => response.json() as EmailAccount));
  }

  //Writes an error message in the browsers console.
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
