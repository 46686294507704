import { Injectable } from '@angular/core';
import {Http} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import {ConnectionService} from '../connection.service';
import {PaginateModel} from '../../Entities/PaginateModel';
import {Observable} from 'rxjs';
import {CollabItemOption} from '../../Entities/CollabItem/CollabItemOption';
import {CollabItemYear} from '../../Entities/CollabItem/CollabItemYear';
import {CollabItemMonthCount} from '../../Entities/CollabItem/CollabItemMonthCount';

@Injectable({
  providedIn: 'root'
})
export class CollabItemService {
  private apiUrl;

  constructor(private http: Http, private httpClient : HttpClient, private connectionService: ConnectionService)
  {
    this.apiUrl = this.connectionService.GetConnectionUrl() + "api/CollabItems";
  }

  private getAuthenticationHeader()
  {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  GetCollabItems(pageModel: PaginateModel): Observable<PaginateModel> {
    console.log(pageModel);
    return this.httpClient
      .post<PaginateModel>(this.apiUrl + '/GetCollabItems', JSON.stringify(pageModel), {
        headers: this.getAuthenticationHeader()
      });
  }

  GetYearRange(pageModel: PaginateModel): Observable<CollabItemYear[]> {
    return this.httpClient
      .post<CollabItemYear[]>(this.apiUrl + '/GetYearRange', JSON.stringify(pageModel), {
        headers: this.getAuthenticationHeader()
      });
  }

  GetCollabItemCountPrMonth(pageModel: PaginateModel): Observable<CollabItemMonthCount[]> {
    return this.httpClient
      .post<CollabItemMonthCount[]>(this.apiUrl + '/GetCollabItemCountPrMonth', JSON.stringify(pageModel), {
        headers: this.getAuthenticationHeader()
      });
  }

  GetCollabItemOptions(column : string, pageModel: PaginateModel): Observable<CollabItemOption[]> {
    return this.httpClient
      .post<CollabItemOption[]>(this.apiUrl + '/GetCollabItemOptions/' + column, JSON.stringify(pageModel), {
        headers: this.getAuthenticationHeader()
      });
  }
}
