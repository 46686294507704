import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CollabItemOption} from '../../../Entities/CollabItem/CollabItemOption';

@Component({
  selector: 'app-multi-drop-down',
  templateUrl: './multi-drop-down.component.html',
  styleUrls: ['./multi-drop-down.component.css']
})
export class MultiDropDownComponent implements OnInit {

  mouseHovering : boolean = false;

  @Input()
  elementId : string;

  selectedOptions : string[] = [];
  @Input()
  set SelectedOptions(selectedOptions : string[]) {
    if(selectedOptions != undefined)
    {
      this.selectedOptions = selectedOptions;
    }
  }

  public _availableOptions : CollabItemOption[];
  @Input()
  set availableOptions(value : CollabItemOption[])
  {
    if(value != undefined) {
      this._availableOptions = value;
    }
  }

  @Output()
  public trySetSelectedOptions = new EventEmitter<string[]>();

  @Output()
  public trySetSelectedOption = new EventEmitter<string>();

  public searchInput : string = "";

  constructor() { }

  ngOnInit() {
  }

  SetSelectedField(field_value: string) {
    this.trySetSelectedOption.emit(field_value);
  }

  EmitSelectedList(selectedFieldList: string[])
  {
    this.trySetSelectedOptions.emit(selectedFieldList);
  }

  closeSelector(id: string) {
    let checkboxes = document.getElementById(id);
    checkboxes.style.display = 'none';

  }
  showSpecificCheckBoxes(id: string) {
    let checkboxes = document.getElementById(id);
    checkboxes.style.display = 'block';
  }

  setValue(event:any)
  {
    this.searchInput = event.target.value;
  }

  mouseEntered()
  {
    this.mouseHovering = true;
  }

  mouseLeft()
  {
    this.mouseHovering = false;
  }
}
