import { Component, Input, OnInit } from "@angular/core";
import { DashboardModel } from "../../Entities/DashboardModel";

@Component({
  selector: "app-dashboard-view",
  templateUrl: "./dashboard-view.component.html",
  styleUrls: ["./dashboard-view.component.css"]
})
export class DashboardViewComponent implements OnInit {
  
  @Input()
  dashboardData: DashboardModel;
  @Input()
  barChartData: any[];

  barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false }
  };

  barChartLabels: string[] = ["Høj", "Mellem", "Lav"];
  barChartType: string = "pie";
  barChartLegend: boolean = true;

  //definition of the background colors for the chart.
  chartColors: any[] = [
    {
      backgroundColor: [
        "rgba(55, 146, 23, 0.79)",
        "rgba(255, 225, 44, 0.7)",
        "#b8436d"
      ]
    }
  ];

  constructor() {}

  ngOnInit() {}

  chartClicked(e: any): void {}

  chartHovered(e: any): void {}

  //Mathmatics to calculate the percentage of approved emails contra pending emails.
  getMailPercentage() {
    var adf =
      (this.dashboardData.InboxCount /
        (this.dashboardData.InboxCount + this.dashboardData.NewMailsCount)) *
      100;
    return adf;
  }

  //Method to set the percentage the progress bar should use.
  getPercentage(fromValue: number, toValue: number) {
    return (fromValue / toValue) * 100;
  }
}
