import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GDPRLog} from '../../../Entities/GDPRLog';
import {GDPRLogService} from '../../../services/gdprlog.service';
import {GDPRSearchCriteria} from '../../../Entities/GDPRSearchCriteria';

@Component({
  selector: 'app-gdprlog-view',
  templateUrl: './gdprlog-view.component.html',
  styleUrls: ['./gdprlog-view.component.css']
})
export class GdprlogViewComponent implements OnInit {
  @Input()
  gdprLogs: GDPRLog[];
  @Output()
  public tryBackEmitter = new EventEmitter();



  constructor()
  {
    console.log(this.gdprLogs);
  }

  ngOnInit() {
  }

  back() {
    this.tryBackEmitter.emit();
  }
}
