import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CriteriaType, FilterCriterion} from '../../../../Entities/FilterCriterion';
import {ApprovalCriteriaService} from '../../../../services/approval-criteria.service';
import {LoginService} from '../../../../services/login.service';
import {ApprovalCriteria} from '../../../../Entities/ApprovalCriteria';
import {EmailService} from '../../../../services/EmailServices/email.service';
import {CollaboratorSetup} from "../../../../Entities/CollaboratorSetup";

@Component({
  selector: 'app-email-filter-config',
  templateUrl: './email-filter-config.component.html',
  styleUrls: ['./email-filter-config.component.css']
})
export class EmailFilterConfigComponent implements OnInit {

  @Output()
  public tryBackEmitter = new EventEmitter();

  public loggedInUserID : number;

  public isLoadingData : boolean;

  whiteList : ApprovalCriteria[] = [];
  blackList : ApprovalCriteria[] = [];

  //itemFields : string[] = Object.getOwnPropertyNames(new ApprovedItem());
  itemFields : any[] =  [{value: "Sender", viewValue: "Afsender"}, {value: "Subject", viewValue: "Emne"}, {value: "BodyText", viewValue: "Hovedtekst"}];


  constructor(private loginService : LoginService, private approvalCriteriaService : ApprovalCriteriaService, private emailService : EmailService) { }


  ngOnInit() {
    this.loggedInUserID = Number(this.loginService.getLoggedInUserID());
    this.approvalCriteriaService.getAllForUser(this.loggedInUserID).subscribe(approvalCriterias => {
      console.log(approvalCriterias);
      this.whiteList = approvalCriterias.filter(x => x.Type == "Approve");
      this.blackList = approvalCriterias.filter(x => x.Type == "Disapprove");
      this.whiteList.push({ID: 0, FilterField: "", Automatic: false, Value: "", Type: CriteriaType.WhiteList });
      this.blackList.push({ID: 0, FilterField: "", Automatic: false, Value: "", Type: CriteriaType.BlackList});
    });
  }

  addCriterion(criterion : ApprovalCriteria)
  {
    criterion.User_ID = this.loggedInUserID;
    this.approvalCriteriaService.create(criterion).subscribe(() => {
      this.ngOnInit();
    });
  }

  removeCriterion(criterion : ApprovalCriteria){

    this.approvalCriteriaService.delete(criterion).subscribe(() => {
      this.ngOnInit();
    });
  }

  validateEmails()
  {
    this.isLoadingData = true;
    this.emailService.validateEmails(this.loggedInUserID).subscribe(() => {console.log("Emails validated"); this.isLoadingData = false;});
  }

  updateCriterion(criterion : ApprovalCriteria)
  {
    if(!this.isCriterionToBeCreated(criterion)) {
      this.approvalCriteriaService.put(criterion).subscribe(updatedCriterion => {
        this.ngOnInit();
      })
    }
  }

  back() {
    this.tryBackEmitter.emit();
  }

  isCriterionToBeCreated(criterion : ApprovalCriteria) {
    if (this.whiteList.includes(criterion)) {
      return (this.whiteList.indexOf(criterion) == this.whiteList.length - 1);
    } else if (this.blackList.includes(criterion)) {
      return (this.blackList.indexOf(criterion) == this.blackList.length - 1);
    }
  }
}
