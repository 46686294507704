import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {User} from '../../Entities/User';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
declare var require: any;

var ServiceConfiguration = require('assets/config.json');

@Component({
  selector: "app-login-view",
  templateUrl: "./login-view.component.html",
  styleUrls: ["./login-view.component.css"]
})
export class LoginViewComponent implements OnInit {

  user: User;
  isForgotPassword: boolean = false;
  passwordResetForm: FormGroup;
  _isEditing: boolean;
  _headlineText : string;
  passwordRepeat: string;
  newPassword: string;

  @Input()
  uniqueState: string;
  @Input()
  userId: string;
  @Input()
  resetToken: string;
  @Input()
  isChangePassword: boolean;
  @Input()
  failureMessage: string;
  @Input()
  successMessage: string;

  @Input()
  set headlineText(text : string)
  {
    this._headlineText = text;
    this.formControlValueChanged(text);
  }

  @Output()
  public tryLoginEmitter = new EventEmitter();
  @Output()
  public tryLoginWithAzureADEmitter = new EventEmitter();
  @Output()
  public trySendResetPasswordEmail = new EventEmitter();
  @Output()
  public tryResetUsersPassword = new EventEmitter();

  constructor(private formBuilder: FormBuilder, private cdRef : ChangeDetectorRef) {
    this.user = new User();
  }

  ngOnInit() {}

  //emits an emailAccount object from the dumb component (this component) to the smart component (LoginComponent)
  tryToLogin() {
    this.tryLoginEmitter.emit(this.user);
  }

  changeViewToForgotPassword() {
    this.isForgotPassword = true;
    this.failureMessage = undefined;
    this.successMessage = undefined;
  }

  changeViewToLogin() {
    this.isForgotPassword = false;
  }

  sendResetPasswordEmail(userEmail: string) {
    this.isForgotPassword = false;
    this.trySendResetPasswordEmail.emit(userEmail);
  }

  resetPassword() {
    const object = {userId: this.userId, passwordResetCode: this.resetToken, newPassword: this.newPassword};
    this.tryResetUsersPassword.emit(object);
    this.cdRef.detectChanges();
  }

  formControlValueChanged(text : any) {
    this.passwordResetForm = this.formBuilder.group({
      password: [null, Validators.required],
      passwordRepeat: [null, Validators.required]
    });
  }

  loginWithMicrosoftAuthentication(){
    let redirectUri = location.origin + '/login';
    let tenant = ServiceConfiguration['graphServiceTenant'];
    let clientId = ServiceConfiguration['graphServiceClientID'];

    window.location.href = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?\n` +
      `client_id=${clientId}\n` +
      `&response_type=code\n` +
      `&redirect_uri=${redirectUri}\n` +
      `&response_mode=query\n` +
      `&prompt=select_account&scope=openid%20user.read`;
  }
}
