import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from 'lodash';
@Pipe({
  name: "SortPipe"
})
export class SortPipe  implements PipeTransform {

  transform(array: any, field: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {

      return (a[field] === b[field]) ? 0 : a[field]? -1 : 1;
    });
    return array;
  }

  /**transform(array: any[], field: string, sortReverse: boolean): any[] {
    if(array != undefined) {
      if (sortReverse) {
        console.log(field);
        console.log(sortReverse);

        array.sort((a: any, b: any) => {
          if (a[field] < b[field]) {
            return -1;
          } else if (a[field] > b[field]) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        array.sort((a: any, b: any) => {
          if (a[field] > b[field]) {
            return -1;
          } else if (a[field] < b[field]) {
            return 1;
          } else {
            return 0;
          }
        });
      }
    }
    return array;
  }*/
}
