import { Injectable } from '@angular/core';
import {Http} from '@angular/http';
import {ConnectionService} from './connection.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ErrorLog} from '../Entities/ErrorLog';
import {ErrorLogFilter} from '../Entities/ErrorLogFilter';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {

  private apiUrl;

  constructor(
    private http: Http,
    private httpClient : HttpClient,
    private connectionService: ConnectionService)
  {
    this.apiUrl = this.connectionService.GetConnectionUrl() + "api/ErrorLog";
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  getFilteredListOfErrors(errorLogFilter: ErrorLogFilter): Observable<any> {
    return this.httpClient
      .post<any>(`${this.apiUrl}/GetFilteredErrors`, errorLogFilter, { headers: this.getAuthenticationHeader() });
  }

  getAllRecordedPlatforms() : Observable<string[]> {
    return this.httpClient
      .get<string[]>(`${this.apiUrl}/GetAllRecordedPlatforms`, { headers: this.getAuthenticationHeader() });
  }

}
