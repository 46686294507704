import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Theme } from "../Entities/Theme";
import { SearchCriteria } from "../Entities/SearchCriteria";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ThemeCriteriaModel } from "../Entities/ThemeCriteriaModel";
import { ConnectionService } from "./connection.service";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ThemeCriteriaService {
  private apiUrl;

  constructor(
    //private http: Http,
    private httpClient : HttpClient,
    private connectionService: ConnectionService
  ) {
    this.apiUrl =
      this.connectionService.GetConnectionUrl() + "api/themecriteria";
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  create(themeID: number, criteria: SearchCriteria): Observable<any> {
    var dataModel = new ThemeCriteriaModel();
    dataModel.criteria = criteria;
    dataModel.themeID = themeID;
    console.log(dataModel);
    const url = `${this.apiUrl}/Add/`;
    return this.httpClient.post<any>(url, JSON.stringify(dataModel), { headers: this.getAuthenticationHeader() });
  }

  createAll(themeID: number, criteria: SearchCriteria[]): Observable<any> {
    const themeCriteriaModel = [];

    for (let i = 0; i < criteria.length; i++) {
      let dataModel = new ThemeCriteriaModel();

      dataModel.criteria = criteria[i];
      dataModel.themeID = themeID;

      themeCriteriaModel.push(dataModel);
    }

    const url = `${this.apiUrl}/AddAll/`;
    return this.httpClient.post<any>(url, JSON.stringify(themeCriteriaModel), {
      headers:  this.getAuthenticationHeader()
    });
  }

  delete(themeID: number, criteria: SearchCriteria): Observable<Theme> {
    var dataModel = new ThemeCriteriaModel();
    dataModel.criteria = criteria;
    dataModel.themeID = themeID;

    const url = `${this.apiUrl}/DeleteReference`;
    return this.httpClient
      .put<Theme>(url, JSON.stringify(dataModel), { headers:  this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as Theme));
  }

  update(
    themeID: number,
    criteria: SearchCriteria,
    newName: string
  ): Observable<Theme> {
    var dataModel = new ThemeCriteriaModel();
    dataModel.criteria = criteria;
    dataModel.themeID = themeID;
    dataModel.newName = newName;
    let headers = this.connectionService.GetAuthorizationHeader();
    const url = `${this.apiUrl}/EditCriteria`;
    return this.httpClient
      .put<Theme>(url, JSON.stringify(dataModel), { headers:  this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as Theme));
  }


  includeEntityInTheme(themeID: number, criteria: SearchCriteria): Observable<any> {
    var dataModel = new ThemeCriteriaModel();
    dataModel.criteria = criteria;
    dataModel.themeID = themeID;
    console.log(dataModel);
    const url = `${this.apiUrl}/IncludeEntityInTheme/`;
    return this.httpClient.post<any>(url, JSON.stringify(dataModel), { headers: this.getAuthenticationHeader() });
  }

  excludeEntityInTheme(themeID: number, criteria: SearchCriteria): Observable<any> {
    var dataModel = new ThemeCriteriaModel();
    dataModel.criteria = criteria;
    dataModel.themeID = themeID;
    console.log(dataModel);
    const url = `${this.apiUrl}/ExcludeEntityFromTheme/`;
    return this.httpClient.post<any>(url, JSON.stringify(dataModel), { headers: this.getAuthenticationHeader() });
  }
}
