import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConnectionService} from './connection.service';
import {Observable} from 'rxjs';
import {User} from '../Entities/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl;
  constructor(private httpClient: HttpClient,
              private connectionService: ConnectionService) {
    this.apiUrl = this.connectionService.GetConnectionUrl() + 'api/User';
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  createUser(user: User): Observable<User> {
    return this.httpClient
      .post<User>(this.apiUrl, JSON.stringify(user),
        { headers: this.getAuthenticationHeader() });
  }
  getUsers(): Observable<User[]> {
    return this.httpClient
      .get<User[]>(this.apiUrl,
        { headers: this.getAuthenticationHeader() });
  }
  getUserById(id: string): Observable<User> {
    return this.httpClient
      .get<User>(this.apiUrl + '/' + id,
        { headers: this.getAuthenticationHeader() });
  }

  getUserByUsername(user: User): Observable<User> {
    return this.httpClient
      .post<User>(this.apiUrl + '/GetByEmailaddress', JSON.stringify(user),
        { headers: this.getAuthenticationHeader() });
  }


  updateUser(user: User): Observable<User> {
    return this.httpClient
      .put<User>(this.apiUrl + '/' + user.ID, JSON.stringify(user),
        { headers: this.getAuthenticationHeader() });
  }
  deleteUser(user: User): Observable<User> {
    return this.httpClient
      .delete<User>(this.apiUrl + '/' + user.ID,
        { headers: this.getAuthenticationHeader() });
  }
}
