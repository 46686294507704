import { Theme } from "./Theme";
import { MailStatusModel } from "./MailStatusModel";

export class DashboardModel {
  InboxCount: number;
  NewMailsCount: number;
  SubscriptionsCount: number;
  ThemeCount: number;
  NewestThemes: Theme[];
  MailStatusChart: MailStatusModel;
}
