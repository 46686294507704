import { Email } from "./EmailEntities/Email";
import { Theme } from "./Theme";

export class SearchCriteria {
  ID?: number;
  Criteria?: string;
  Themes?: Theme[];
  Emails?: Email[];
  IsDefault?: boolean;
}
