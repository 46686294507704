import { Injectable } from "@angular/core";
import { ConnectionService } from "./connection.service";
import { Http } from "@angular/http";
import { DashboardModel } from "../Entities/DashboardModel";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LoginService } from "./login.service";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DashboardService {
  private apiUrl;

  constructor(
    //private http: Http,
    private httpClient: HttpClient,
    private connectionService: ConnectionService,
    private loginService: LoginService
  ) {
    this.apiUrl = this.connectionService.GetConnectionUrl() + "api/Dashboard";
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  getDashboardData(): Observable<DashboardModel> {
    let userID = Number(this.loginService.getLoggedInUserID());
    return this.httpClient
      .get<DashboardModel>(this.apiUrl + "/GetDashboardData/" + userID, { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as DashboardModel));
  }
}
