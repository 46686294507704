import { Component, OnInit } from "@angular/core";
import { Theme } from "../../Entities/Theme";
import { ThemeService } from "../../services/theme.service";
import { SearchCriteria } from "../../Entities/SearchCriteria";
import { Observable } from "rxjs";
import { ThemeCriteriaService } from "../../services/theme-criteria.service";
import { ThemePaginateModel } from "../../Entities/ThemePaginateModel";
import { LoginService } from "../../services/login.service";
import { SubscriptionService } from "../../services/subscription.service";
import { MatSnackBar } from "@angular/material";
import {ApprovedItem} from '../../Entities/EmailEntities/ApprovedItem';
import {ClickHistory} from '../../Entities/ClickHistory';
import {ClickHistoryService} from '../../services/click-history.service';
import {Router} from '@angular/router';

@Component({
  selector: "app-themes-controller",
  templateUrl: "./themes-controller.component.html",
  styleUrls: ["./themes-controller.component.css"]
})

export class ThemesControllerComponent implements OnInit {
  themes: Theme[];
  themeCriterias: SearchCriteria[];

  currentPage: number = 1;
  pageSize: number = 10;
  themeCount: number;
  savingThemeToDatabase: boolean;

  searchInput : string = "";

  sortFields: string[] = Object.getOwnPropertyNames(new Theme());
  sortParameter: string = this.sortFields[2];
  sortReversed: boolean = false;

  constructor(
    private themeService: ThemeService,
    private themeCriteriaService: ThemeCriteriaService,
    private loginService: LoginService,
    private subscribeService: SubscriptionService,
    private snackBar: MatSnackBar,
    private clickHistoryService: ClickHistoryService,
    private router : Router

) {
    this.savingThemeToDatabase = false;
    // Adds the current sortParameter to the front of the array
    //this.sortFields.unshift(this.sortParameter);
  }

  /**
   * Initialized the component by creating a ThemePaginateModel and fetches the first array of Theme objects
   */
  ngOnInit() {
    this.getThemesForPage();
  }

  /**
   * Gets a array of Theme objects
   */
  getThemesForPage() {
    let pagemodel = new ThemePaginateModel();
    pagemodel.UserID = Number(this.loginService.getLoggedInUserID());
    pagemodel.PageIndex = this.currentPage;
    pagemodel.PageSize = this.pageSize;
    pagemodel.SearchInput = this.searchInput;
    pagemodel.SortParameter = this.sortParameter;
    pagemodel.SortReversed = this.sortReversed;

    this.themeService.getThemesForPage(pagemodel).subscribe(model => {
      (this.themes = model.Themes),
        (this.themeCount = model.Total),
        this.savingThemeToDatabase = false
    });
  }

  /**
   * Creates the given Theme through the ThemeService, calls local function "createThemeCriterias()", and refreshed the page
   * @param theme
   */
  createTheme(theme: Theme) {
    this.savingThemeToDatabase = true;
    this.themeCriterias = theme.SearchCriterias;
    theme.SearchCriterias = [];
    let pagemodel = new ThemePaginateModel();
    pagemodel.PageIndex = this.currentPage;
    pagemodel.PageSize = this.pageSize;
    if (this.themeCriterias.length > 0) {
      this.themeService.create(theme).subscribe(x => {
        console.log(theme);
        this.createThemeCriterias(x.ID).subscribe(() => {
          this.getThemesForPage();
        });
      });
    } else {
      this.themeService.create(theme).subscribe(() => {
        this.getThemesForPage();
    });
    }
  }

  /**
   * Creates all the Search Criterias from the themeCriterias array and adds it to the given themeID through the ThemeCriteriaService
   * @param themeID
   */
  createThemeCriterias(themeID: number): Observable<any> {
    return this.themeCriteriaService.createAll(themeID, this.themeCriterias);
  }

  /**
   * Deleted the given Theme through the ThemeService
   * @param theme
   */
  deleteTheme(theme: Theme) {
    this.themeService
      .delete(theme)
      .subscribe(() => this.getThemesForPage());
  }

  /**
   * Sets the currentPage
   * @param currentPage
   */
  setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;
  }

  /**
   * Sets the pageSize
   * @param pageSize
   */
  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  /**
   * Created a subscription through the SubscribeService and refreshed the page
   * @param theme
   */
  subscribeToTheme(theme: Theme) {
    this.subscribeService
      .addSubscription(theme, Number(this.loginService.getLoggedInUserID()))
      .subscribe(() => {
        this.snackBar.open("Du er nu subscribed til " + theme.Title, "Ok", {
          duration: 3000
        }),
          this.getThemesForPage();
      });
  }

  /**
   * Calls deleteSubscription on the SubscribeService and refreshed the page
   * @param theme
   */
  unsubscribeFromTheme(theme: Theme) {
    this.subscribeService
      .deleteSubscription(theme, Number(this.loginService.getLoggedInUserID()))
      .subscribe(() => {
        this.snackBar.open("Du er nu unsubscribed til " + theme.Title, "Ok", {
          duration: 3000
        }),
          this.getThemesForPage();
      });
  }

  /**
   * Sets the sortParameter
   * @param sortParameter
   */
  changeSortParameter(sortParameter: string) {
    this.sortParameter = sortParameter;
  }

  /**
   * Sets the sortReversed
   * @param isReversed
   */
  changeSortReversed(isReversed: boolean) {
    this.sortReversed = isReversed;
  }

  /**
   * Sets the searchInput
   * @param input
   */
  changeSearchInput(input: string) {
    this.searchInput = input;
    this.currentPage = 1;
    this.getThemesForPage();
  }

  /**
   * Registers the click event on the given theme, and redirects to the theme detail page
   * @param theme
   */
  routeToTheme(theme : Theme)
  {
    this.createClickHistoryItem(theme);
    this.router.navigate(['/themes/' + theme.ID]);
  }

  /**
   * Created ClickHistoryItem with Entry type as Theme
   * @param theme Theme
   */
  createClickHistoryItem(theme: Theme) {
    let clickHistoryItem: ClickHistory;

    let userId = Number.parseInt(this.loginService.getLoggedInUserID());

    clickHistoryItem =
      ({
        ID: 0,
        EntryId: theme.ID,
        LastViewed: new Date(),
        EntryTitle: theme.Title,
        User: {ID: userId},
        EntryType: 'Theme'
      });
    if (clickHistoryItem.EntryTitle !== '' && clickHistoryItem.EntryType !== '') {

      this.clickHistoryService.createClickHistoryEntry(clickHistoryItem).subscribe(() =>
      {
        this.clickHistoryService.historyWasChanged();
      });
    }
  }

}
