export class ApprovedItem {
    ID: number;
    GuidID: string;
    Subject: string = ""; // 1
    Sender: string = ""; // 1
    Recipiant: string = ""; // 1
    ReceivedDate: Date = null; // 1 & rank
    IsMail: number;
    ItemReferenceCount: number;

}
