import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConnectionService} from './connection.service';
import {Observable, Subject} from 'rxjs';
import {ClickHistory} from '../Entities/ClickHistory';

@Injectable({
  providedIn: 'root'
})
export class ClickHistoryService {

  private apiUrl;

  public historyUpdatedSubject: Subject<boolean>;
  private historyChanged : Observable<any>;

  constructor(
    private httpClient: HttpClient,
    private connectionService: ConnectionService
  ) {
    this.apiUrl = this.connectionService.GetConnectionUrl() + 'api/ClickHistory';

    this.historyUpdatedSubject = new Subject<boolean>();
    this.historyChanged = this.historyUpdatedSubject.asObservable();
    this.historyUpdatedSubject.next(false);

  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  hasHistoryChanged(){
    return this.historyChanged;
  }

  historyWasChanged(){
    this.historyUpdatedSubject.next(true);
  }

  createClickHistoryEntry(clickHistory: ClickHistory): Observable<ClickHistory> {
    return this.httpClient
      .post<ClickHistory>(this.apiUrl, JSON.stringify(clickHistory), { headers: this.getAuthenticationHeader() });
  }

  getLatestClickHistory(): Observable<ClickHistory[]> {
    return this.httpClient
      .get<ClickHistory[]>(this.apiUrl, { headers: this.getAuthenticationHeader() });
  }

  getClickHistoryByUser(userId: string, amount: number): Observable<ClickHistory[]> {
    return this.httpClient
      .get<ClickHistory[]>(this.apiUrl + "/GetByUser/" + userId + "/" + amount, { headers: this.getAuthenticationHeader() });
  }

  getClickHistoryById(id: string): Observable<ClickHistory[]> {
    return this.httpClient
      .get<ClickHistory[]>(this.apiUrl + "/" + id, { headers: this.getAuthenticationHeader() });
  }

  updateClickHistoryEntry(clickHistory: ClickHistory): Observable<ClickHistory> {
    return this.httpClient
      .put<ClickHistory>(this.apiUrl + '/' + clickHistory.ID, JSON.stringify(clickHistory), {
        headers: this.getAuthenticationHeader()
      });
  }

  deleteClickHistoryEntry(clickHistory: ClickHistory): Observable<ClickHistory> {
    return this.httpClient
      .delete<ClickHistory>(this.apiUrl + '/' + clickHistory.ID, { headers: this.getAuthenticationHeader() });
  }
}
