import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ApprovedAttachment } from '../../Entities/EmailEntities/ApprovedAttachment';
import { ApprovedAttachmentService } from '../../services/approved-attachment.service';
import {LoginService} from '../../services/login.service';
import {User} from '../../Entities/User';
import {EmailAccountService} from '../../services/email-account.service';

@Component({
  selector: 'app-file-upload-view',
  templateUrl: './file-upload-view.component.html',
  styleUrls: ['./file-upload-view.component.css']
})
export class FileUploadViewComponent implements OnInit {

  loading = false;
  form: FormGroup;
  filesToUpload: ApprovedAttachment[];
  loggedInUserID : string;

  @ViewChild('myInput')
  fileChooserValue: ElementRef;

  constructor(private fb: FormBuilder,
              private approvedAttachmentService: ApprovedAttachmentService,
              private snackBar: MatSnackBar,
              private userService : EmailAccountService,
              private loginService : LoginService) {
    this.filesToUpload = [];
    this.form = this.fb.group({
      fileChooser: null
    });
  }

  ngOnInit() {
    this.loggedInUserID = this.loginService.getLoggedInUserID()
  }

  uploadFiles() {
    if (this.filesToUpload.length > 0) {
      console.log(this.filesToUpload);
      this.loading = true;
      console.log(this.filesToUpload);
      this.approvedAttachmentService.uploadFiles(this.filesToUpload).subscribe(success => {
        this.loading = false;
        const length = this.filesToUpload.length;
        if (success.length === 0)
        {
          this.snackBar.open(length - success.length + ' fil/filer eksisterer allerede. ', 'OK', {duration: 3000});
        }
        for (let j = 0; j < length; j++)
        {
          this.removeFile(this.filesToUpload[0]);
        }
      });
    } else {
      const message = 'Vælg filer der skal uploades før du trykker upload'
      this.snackBar.open(message, 'OK', {duration: 3000});
    }
  }

  onFileChange($event) {
    let self = this;

    const files = $event.target.files;
    const filesToUpload = this.filesToUpload;
    const fcValue = this.fileChooserValue;

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileReader = new FileReader();
        let textFromFileLoaded;
        fileReader.onload = function(fileLoadedEvent) {
          textFromFileLoaded = fileLoadedEvent.target.result;

          const newFile = new ApprovedAttachment();
          // base 64 encode
          newFile.FileContent = btoa(textFromFileLoaded);
          newFile.FileName = files[i].name;
          newFile.DateTime = new Date();

          let fuckingUser = new User();
          fuckingUser.ID = parseInt(self.loggedInUserID);
          newFile.user = fuckingUser;
          filesToUpload.push(newFile);
          if (i === files.length - 1) {
            fcValue.nativeElement.value = '';
          }
        };
        fileReader.readAsBinaryString(file);
      }
    }
    // reset doesnt work. we need to reset because we cant select a file, remove it, and select it again
  }

  removeFile(file: ApprovedAttachment) {
    const index = this.filesToUpload.indexOf(file);
    if (index > -1) {
      this.filesToUpload.splice(index, 1);
    }
  }
}
