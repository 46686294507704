import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import 'rxjs/add/operator/do';
import {Observable, throwError} from 'rxjs';
import {ErrorModalComponent} from '../../error-modal/error-modal.component';
import {MatDialog} from '@angular/material';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private modal: MatDialog) {}

  openErrorModal(errStatus, errMessage) {
    this.modal.open(ErrorModalComponent, {
      disableClose: true,
      panelClass: 'confirm-dialog-container',
      data: {
        errorStatus: errStatus,
        errorMessage: errMessage
      }
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).do((event: HttpEvent<any>) => {}, (err: any) => {
      if (err instanceof HttpErrorResponse) {

        if(err.error != null)
        {
          console.log("Error Message: " + err.error.Message);
        }


        console.log(err.message);

        //open error modal window with error message
        this.openErrorModal(err.statusText, err.error);

        // Error message that given from the API: [err.error.Message]
        // Could be a id which the programmer could use to see the real logged error
        //alert('Hovsa. Der er opstået en fejl. Kontakt venligst system administratoren. \n Fejl besked: ' + err.message  );
        if(err.status == 400)
        {

        }

        return throwError(err);
      }
    });
  }
}
