import { Injectable } from "@angular/core";
import { Headers, Http } from "@angular/http";
import { EmailAccountService } from "./email-account.service";
import { EmailAccount } from "../Entities/EmailAccount";
import { Router } from "@angular/router";
import { Subject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConnectionService } from "./connection.service";
import {selectValueAccessor} from '@angular/forms/src/directives/shared';
import {Theme} from '../Entities/Theme';
import {User} from '../Entities/User';
import {HttpHeaders} from "@angular/common/http";

@Injectable()
export class LoginService {
  requestString: string;
  userId: number;
  params: string;
  feedback: { access_token: string };
  private apiUrl;
  private headers: Headers;

  public loggedSubject: Subject<boolean>;
  public isLogged: any;

  constructor(
    private http: Http,
    private router: Router,
    private connectionService: ConnectionService
  ) {
    this.apiUrl = this.connectionService.GetConnectionUrl();
    this.feedback = { access_token: "" };

    this.loggedSubject = new Subject<boolean>();
    this.isLogged = this.loggedSubject.asObservable();
    this.loggedSubject.next(false);
  }

  login(user: User): Observable<any> {
    this.params = JSON.stringify(user);

    if(!!user.AuthToken){
      this.requestString = `grant_type=auth_code&code=${user.AuthToken}`
    }
    else{
      this.requestString =
       "grant_type=password&username=" +
       user.EmailAdress +
       " &password=" +
       user.Password;
    }

    return this.http
      .post(this.apiUrl + "token", this.requestString, {
        headers: this.headers
      })
      .pipe(
        map(resp => {
          (this.feedback = resp.json()),
            sessionStorage.setItem("token", this.feedback.access_token),
            this.loggedSubject.next(true)
        })
      ).map((response) => this.feedback);
  }

  setSession(id: number, emailAddress: string, isAdmin : boolean): Observable<boolean> {
    return new Observable<boolean>(observer => {
      if (id != null && id) {
        sessionStorage.setItem("userId", id + "");
        sessionStorage.setItem("emailAddress", emailAddress);
        sessionStorage.setItem("isAdmin", isAdmin + "");
        //KG & CSK 290519 Added emailAccountID
        //sessionStorage.setItem("emailAccountID", emailAccountID + "");
        //sessionStorage.setItem("token", this.feedback.access_token);
        this.loggedSubject.next(true);
        observer.next(true);
      } else {
        this.loggedSubject.next(false);
        observer.next(false);
      }
    });
  }

  logout() {
    sessionStorage.setItem("userId", "");
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("emailAddress", "");
    sessionStorage.setItem("isAdmin", "");
    this.loggedSubject.next(false);
    this.router.navigate(["/login"]);
  }

  getLoggedInUserID() {
    return sessionStorage.getItem("userId");
  }

  getLoggedInUserEmailAddress()
  {
    return sessionStorage.getItem("emailAddress");
  }
  isAdminLoggedIn() : boolean
  {
    return sessionStorage.getItem("isAdmin") == "true";
  }
  isLoggedIn(): boolean {
    return (
      sessionStorage.getItem("userId") != null &&
      sessionStorage.getItem("userId") &&
      parseInt(sessionStorage.getItem("userId"), 10) > 0
    );
  }

  checkAccesToken() {
    return this.isLogged;
  }

  changePassword(account : User)
  {
    let headers = this.connectionService.GetAuthorizationHeader();
    return this.http
      .post(this.apiUrl + "api/Account/ChangePasswordFromExtSite", JSON.stringify(account), { headers: headers })
      .pipe(map(response => response.json() as any))
  }

  getLastVisitedEmailAccount() {
    return sessionStorage.getItem("lastVisitedEmailAccount");
  }

  setLastVisitedEmailAccount(userId : string) {
    return sessionStorage.setItem("lastVisitedEmailAccount", userId);
  }

  getLastVisitedUser() {
    return sessionStorage.getItem("lastVisitedUserId");
  }

  setLastVisitedUser(userId : string) {
    return sessionStorage.setItem("lastVisitedUserId", userId);
  }

  sendResetPasswordEmail(emailAddress : string): Observable<string> {
    let headers = this.connectionService.GetAuthorizationHeader();
    return this.http
      .post(this.apiUrl + "api/Account/SendResetPasswordEmail", {email: emailAddress}, { headers: headers })
      .pipe(map(response => response.json() as string));
  }

  validatePasswordChangeToken(passwordChangeModel): Observable<boolean> {
    let headers = this.connectionService.GetAuthorizationHeader();
    return this.http
      .post(this.apiUrl + "api/Account/ValidateResetPasswordToken", JSON.stringify(passwordChangeModel), { headers: headers })
      .pipe(map(response => response.json() as boolean));
  }

  changeForgottenPassword(passwordChangeModel): Observable<string> {
    let headers = this.connectionService.GetAuthorizationHeader();
    return this.http
      .put(this.apiUrl + "api/Account/ResetPassword", JSON.stringify(passwordChangeModel), { headers: headers })
      .pipe(map(response => response.json() as string));
  }

  // KG & CSK 290519 Added emailAccountID
  getLoggedInEmailAccountID() {
    return sessionStorage.getItem("userId");
  }
}



