import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../Entities/User';
import {EmailAccount} from '../../Entities/EmailAccount';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-user-controller-view',
  templateUrl: './user-controller-view.component.html',
  styleUrls: ['./user-controller-view.component.css']
})
export class UserControllerViewComponent implements OnInit {

  @Output()
  public tryDeleteUserEmitter = new EventEmitter();
  @Output()
  public tryDeleteEmailAccountEmitter = new EventEmitter();
  @Output()
  public trySaveEmailAccountEmitter = new EventEmitter();

  @Input()
  users: User[];

  constructor(private router: Router) { }

  ngOnInit() {

  }

  deleteUser(user: User) {
    if (confirm('Er du sikker på at du vil slette denne bruger? \r\n \r\n Denne handling kan ikke fortrydes.')) {
      this.tryDeleteUserEmitter.emit(user);
    }
  }
}
