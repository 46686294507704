import {User} from '../User';
import {Email} from './Email';

export class ApprovedAttachment {
  ID?: number;
  FileName?: string;
  FileTextContent?: string;
  FileType: string;
  email: Email;
  FileContent?: any;
  DateTime: Date;
  user: User;
  ManualIndexingData: string;

  ServiceId?: string;
}
