import { Injectable } from '@angular/core';
import {Http} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import {ConnectionService} from './connection.service';
import {Observable} from 'rxjs';
import {CollaboratorSetup} from '../Entities/CollaboratorSetup';
import {GDPRSearchCriteria} from '../Entities/GDPRSearchCriteria';
import {Theme} from '../Entities/Theme';
import {ThemeRequest} from '../Entities/ThemeRequest';
import {EmailAccount} from '../Entities/EmailAccount';

@Injectable({
  providedIn: 'root'
})
export class GDPRSearchCriteriaService {
  private apiUrl;

  constructor(
    private http: Http,
    private httpClient : HttpClient,
    private connectionService: ConnectionService)
  {
    this.apiUrl = this.connectionService.GetConnectionUrl() + "api/GDPRSearchCriteria";
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  get(id: number): Observable<GDPRSearchCriteria> {
    return this.httpClient
      .get<GDPRSearchCriteria>(this.apiUrl + "/" + id, { headers: this.getAuthenticationHeader() });
  }
  getAll(): Observable<GDPRSearchCriteria[]> {
    return this.httpClient
      .get<GDPRSearchCriteria[]>(this.apiUrl, { headers: this.getAuthenticationHeader() });
  }
  create(gdprSearchCriteria: GDPRSearchCriteria): Observable<GDPRSearchCriteria> {
      return this.httpClient
      .post<GDPRSearchCriteria>(this.apiUrl, JSON.stringify(gdprSearchCriteria), { headers: this.getAuthenticationHeader() });
  }
  delete(gdprSearchCriteria: GDPRSearchCriteria): Observable<GDPRSearchCriteria> {
    return this.httpClient
      .delete<GDPRSearchCriteria>(this.apiUrl + "/" +gdprSearchCriteria.ID, { headers: this.getAuthenticationHeader() });
  }
}
