import { Component, OnInit, Output, OnDestroy } from "@angular/core";
import { EmailAccount } from "../../Entities/EmailAccount";
import { EmailAccountService } from "../../services/email-account.service";
import { SignalR, ISignalRConnection } from "ng2-signalr";
import {GDPRSearchCriteriaService} from '../../services/gdprsearch-criteria.service';

@Component({
  selector: "app-email-account-controller",
  templateUrl: "./email-account-controller.component.html",
  styleUrls: ["./email-account-controller.component.css"]
})
export class EmailAccountControllerComponent implements OnInit, OnDestroy {
  emailAccounts: EmailAccount[];
  isPressedBool: boolean;
  conn: ISignalRConnection;

  constructor(
    private accountService: EmailAccountService,
    private _signalR: SignalR
  ) {
    this.emailAccounts = [];
    this.accountService.getAll().subscribe(accounts => {
      this.resetProgressBar(accounts);
    });
  }

  ngOnInit() {
    //this.signalR();
  }

  //Method for establishing a connection to the backend and listening for changes.
  //When a change accur, a visual progress bar appears and the percentage until finished will adjust itself each change.
  signalR() {
    this._signalR.connect().then(c => {
      this.conn = c;
      const message = this.conn.listenFor("hello");
      console.log("listening for hello");

      message.subscribe((data: string) => {
        this.emailAccountSwitch(data);

        console.log(data, new Date());
      });
    });
  }

  //Closes the connection to the backend, when the component is destroyed.
  ngOnDestroy() {
    if (this.conn) {
      this.conn.stop();
    }
  }

  //Makes it possible for multiple progress bars to appear. One for each email account which is loading emails/files.
  emailAccountSwitch(data: string) {
    const message = data.split(" ");
    this.emailAccounts.forEach(element => {
      if (element.EmailAddress === message[1]) {
        element.Progress = +message[0];
      }
    });
  }

  //Resets the progress bars when all emails/files are loaded.
  resetProgressBar(accounts: EmailAccount[]) {
    this.emailAccounts = accounts;
    accounts.forEach(element => {
      element.Progress = 0;
    });
  }

  //Through the delete() method in AccountService, the email account corresponding to the value parsed into the parameter is deleted.
  //When the account is deleted, the getAll() method is ran to refresh the list of email accounts.
  //At last, the progress bar is removed for the email account in question.
  deleteEmailAccount(account: EmailAccount) {
    this.isPressedBool = true;
    this.accountService.delete(account).subscribe(() =>
      this.accountService.getAll().subscribe(accounts => {
        this.resetProgressBar(accounts);
        this.isPressedBool = false;
      })
    );
  }
}
