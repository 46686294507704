import {AfterViewInit, Component, NgZone, OnInit} from '@angular/core';
import {EmailAccountService} from '../../../services/email-account.service';
import {EmailAccount} from '../../../Entities/EmailAccount';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../Entities/User';
import {LoginService} from '../../../services/login.service';
import {MatSnackBar} from '@angular/material/snack-bar';
declare var gapi: any;
declare var require: any;

var ServiceConfiguration = require('assets/config.json');

@Component({
  selector: 'app-email-account-create',
  templateUrl: './email-account-create.component.html',
  styleUrls: ['./email-account-create.component.css']
})
export class EmailAccountCreateComponent implements OnInit, AfterViewInit {


  errorMessage: string;

  userIdFromRoute : string;

  constructor(
    private emailAccountService: EmailAccountService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone : NgZone,
    private snackBar: MatSnackBar,
    private loginService : LoginService,

  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log(this.userIdFromRoute);
      if (params["id"]) {
        this.userIdFromRoute = (params['userid']);
      } else {
        this.userIdFromRoute = !!this.loginService.getLastVisitedUser() && this.loginService.getLastVisitedUser() != "" ?
          this.loginService.getLastVisitedUser() : this.loginService.getLoggedInUserID();
      }
    });

  }

  //Method addEmailAccount returns a response from method create() in EmailAccountService through a subscription by parsing email/password into its parameter.
  //If successful, the user is routed to emailaccounts and if not, an error message is displayed.
  addEmailAccount(emailAccount: EmailAccount) {
    //removes id from existing first emailaccount
    if (this.userIdFromRoute) {
      emailAccount.User.ID = parseInt(this.userIdFromRoute);
    }
    this.emailAccountService
      .create(emailAccount)
      .subscribe(
        account => {this.ngZone.run(() => {
          this.snackBar.open('Email konto: ' + account.EmailAddress + ' er nu oprettet', 'OK', {
            duration: 3000
          });
          this.router.navigate(['/user-detail/' + this.userIdFromRoute])
        }, 5); },
        err =>
          (this.errorMessage =
            'Emailen/kodeordet er forkert eller emailen eksisterer allerede.')
      );
  }


  logInWithGoogle(emailAccount : EmailAccount) {
    const auth3 = gapi.auth2.getAuthInstance();
    auth3.grantOfflineAccess().then(authCode => {
      emailAccount.RefreshToken = authCode.code;
      console.log(emailAccount.RefreshToken);
      if (!!emailAccount.RefreshToken) {
        console.log(emailAccount);
        this.addEmailAccount(emailAccount);
      }
    });
  }

  public googleInit() {
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: ServiceConfiguration['gmailServiceClientID'],
        fetch_basic_profile: false,
        scope: 'https://www.googleapis.com/auth/gmail.readonly'
      });
    });
  }
  ngAfterViewInit(): void {
    this.googleInit();
  }
}

