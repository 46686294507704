import { CdkTableModule } from '@angular/cdk/table';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTableModule,
  MatNativeDateModule
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { SignalRConfiguration, SignalRModule } from 'ng2-signalr';
import { TagInputModule } from 'ngx-chips';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import {NgProgressInterceptor, NgProgressModule} from 'ngx-progressbar';
import { AppComponent } from './app.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { LoginViewComponent } from './auth/login/login-view.component';
import { LoginComponent } from './auth/login/login.component';
import { ApprovedAttachmentDetailViewComponent } from './controllers/attachment-controller/approved-attachment-detail/approved-attachment-detail-view.component';
import { DashboardViewComponent } from './controllers/dashboard/dashboard-view.component';
import { DashboardComponent } from './controllers/dashboard/dashboard.component';
import { EmailAccountControllerViewComponent } from './controllers/email-account-controller/email-account-controller-view.component';
import { EmailAccountControllerComponent } from './controllers/email-account-controller/email-account-controller.component';
import { EmailAccountCreateViewComponent } from './controllers/email-account-controller/email-account-create/email-account-create-view.component';
import { EmailAccountCreateComponent } from './controllers/email-account-controller/email-account-create/email-account-create.component';
import { EmailAccountDetailComponent } from './controllers/email-account-controller/email-account-detail/email-account-detail.component';
import { EmailAccountDetailsViewComponent } from './controllers/email-account-controller/email-account-detail/email-account-details-view.component';
import { EmailControllerViewComponent } from './controllers/email-controller/email-controller-view.component';
import { EmailControllerComponent } from './controllers/email-controller/email-controller.component';
import { EmailDetailViewComponent } from './controllers/email-controller/email-detail/email-detail-view.component';
import { EmailFilterViewComponent } from './controllers/email-controller/email-filter/email-filter-view.component';
import { EmailFilterComponent } from './controllers/email-controller/email-filter/email-filter.component';
import { FileUploadViewComponent } from './controllers/file-upload/file-upload-view.component';
import { ThemesControllerViewComponent } from './controllers/themes-controller/themes-controller-view.component';
import { ThemesControllerComponent } from './controllers/themes-controller/themes-controller.component';
import { ApprovedAttachmentService } from './services/approved-attachment.service';
import { ConnectionService } from './services/connection.service';
import { DashboardService } from './services/dashboard.service';
import { EmailAccountService } from './services/email-account.service';
import { ApprovedEmailService } from './services/EmailServices/approved-email.service';
import { DisapprovedEmailService } from './services/EmailServices/disapproved-email.service';
import { EmailService } from './services/EmailServices/email.service';
import { LoginService } from './services/login.service';
import { PendingAttachmentService } from './services/pending-attachment.service';
import { SubscriptionService } from './services/subscription.service';
import { ThemeCriteriaService } from './services/theme-criteria.service';
import { ThemeService } from './services/theme.service';
import { ToolbarComponent } from './toolbar/toolbar.component';
import {HttpModule} from '@angular/http';
import { EmailFilterConfigComponent } from './controllers/email-controller/email-filter/email-filter-config/email-filter-config.component';
import {ApprovalCriteriaService} from './services/approval-criteria.service';
import {SearchPipe} from './pipes/SearchPipe';
import {SortPipe} from './pipes/SortPipe';
import { CollaboratorSetupComponent } from './controllers/collaborator-setup/collaborator-setup.component';
import {CollaboratorSetupService} from './services/collaborator-setup.service';
import {RequestInterceptor} from './services/interceptors/RequestInterceptor';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import { GDPRComponent } from './controllers/gdpr/gdpr.component';
import { GDPRViewComponent } from './controllers/gdpr/gdpr-view.component';
import { GdprlogViewComponent } from './controllers/gdpr/gdprlog-view/gdprlog-view.component';
import {FileFunctionalityService} from './services/functionalities/file-functionality.service';
import { UserControllerComponent } from './controllers/user-controller/user-controller.component';
import {AdminGuardService} from './auth/admin-guard.service';
import { UserCreateComponent } from './controllers/user-controller/user-account-create/user-create.component';
import { UserCreateViewComponent } from './controllers/user-controller/user-account-create/user-create-view.component';
import { UserDetailComponent } from './controllers/user-controller/user-account-detail/user-detail.component';
import { UserControllerViewComponent } from './controllers/user-controller/user-controller-view.component';
import {ContextMenuModule} from 'ngx-contextmenu';
import {ShContextMenuModule} from 'context-menu-angular6';
import {MatDialogModule} from '@angular/material/dialog';
import {ErrorModalComponent} from './error-modal/error-modal.component';
import {MatIconModule} from '@angular/material/icon';
import {ImageViewerComponent, ImageViewerModule} from 'ng2-image-viewer';
import {PasswordResetGuardService} from "./auth/password-reset-guard.service";
import {ErrorLogComponent} from "./controllers/error-log/error-log.component";
import { ErrorLogViewComponent } from './controllers/error-log/error-log-view/error-log-view.component';
import { ErrorLogService } from './services/error-log.service';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {CollabItemService} from './services/CollabItem/collab-item.service';
import { MultiDropDownComponent } from './controllers/View-Helpers/multi-drop-down/multi-drop-down.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  {path: 'change-password',
    component: LoginComponent,
    canActivate: [PasswordResetGuardService]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'emails',
    component: EmailControllerComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'emails/:itemId/:itemType/:returnUrl',
    component: EmailControllerComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'themes/:themeId',
    component: EmailControllerComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'themes',
    component: ThemesControllerComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'login', component: LoginComponent },
  { path: 'login/:userame/:password/:theme', component: LoginComponent },
  {
    path: 'emailaccounts',
    component: EmailAccountControllerComponent,
    canActivate: [AdminGuardService]
  },
  {
    path: 'emailaccount-create',
    component: EmailAccountCreateComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'emailaccount-create/:userid',
    component: EmailAccountCreateComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'emailaccount-detail',
    component: EmailAccountDetailComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'emailaccount-detail/:id',
    component: EmailAccountDetailComponent,
    canActivate: [AuthGuardService]
  },

  /*{
    path: 'email-filter',
    component: EmailFilterComponent,
    canActivate: [AuthGuardService]
  },*/
  {
    path: 'email-filter/:type',
    component: EmailFilterComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'email-filter-config',
    component: EmailFilterConfigComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'fileupload',
    component: FileUploadViewComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'setup',
    component: CollaboratorSetupComponent,
    canActivate: [AdminGuardService]
  },
  {
    path: 'gdpr',
    component: GDPRComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'gdpr/gdprlog',
    component: GdprlogViewComponent,
    canActivate: [AuthGuardService]
  },
  {path: 'users',
    component: UserControllerComponent,
    canActivate: [AdminGuardService]
  },
  {path: 'user-detail/:id',
    component: UserDetailComponent,
    canActivate: [AuthGuardService]
  },
  {path: 'user-detail',
    component: UserDetailComponent,
    canActivate: [AuthGuardService]
  },
  {path: 'user-create',
    component: UserCreateComponent,
    canActivate: [AdminGuardService]
  },
  {path: 'error-log',
    component: ErrorLogComponent,
    canActivate: [AdminGuardService]
  }
];

// >= v2.0.0
export function createConfig(): SignalRConfiguration {
  // >= v5.0.0
  const c = new SignalRConfiguration();
  c.hubName = 'Progress';
  c.qs = { user: 'donald' };
  c.url = 'http://localhost:7984/signalr';
  // c.url = 'http://api.collaborator.dev.eliteit.dk/signalr';
  c.logging = true;
  c.executeEventsInZone = true; // optional, default is true
  c.executeErrorsInZone = false; // optional, default is false
  c.executeStatusChangeInZone = true; // optional, default is true
  return c;
}

@NgModule({
  declarations: [
    AppComponent,
    EmailControllerComponent,
    EmailControllerViewComponent,
    ToolbarComponent,
    LoginComponent,
    LoginViewComponent,
    ThemesControllerComponent,
    ThemesControllerViewComponent,
    EmailDetailViewComponent,
    EmailAccountControllerComponent,
    EmailAccountControllerViewComponent,
    EmailAccountCreateComponent,
    EmailAccountDetailComponent,
    EmailAccountDetailsViewComponent,
    EmailAccountCreateViewComponent,
    DashboardComponent,
    DashboardViewComponent,
    EmailFilterComponent,
    EmailFilterViewComponent,
    ApprovedAttachmentDetailViewComponent,
    FileUploadViewComponent,
    EmailFilterConfigComponent,
    SearchPipe,
    SortPipe,
    CollaboratorSetupComponent,
    GDPRComponent,
    GDPRViewComponent,
    GdprlogViewComponent,
    UserControllerComponent,
    UserCreateComponent,
    UserCreateViewComponent,
    UserDetailComponent,
    UserControllerViewComponent,
    ErrorModalComponent,
    ErrorLogComponent,
    ErrorLogViewComponent,
    MultiDropDownComponent,
  ],
  entryComponents: [ErrorModalComponent],

    imports: [
        MatIconModule,
        BrowserModule,
        HttpClientModule,
        HttpModule,
        FormsModule,
        ReactiveFormsModule,
        ChartsModule,
        TagInputModule,
        OrderModule,
        CdkTableModule,
        NoopAnimationsModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatCheckboxModule,
        MatButtonModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonToggleModule,
        PdfJsViewerModule,
        NgxPaginationModule,
        MatDialogModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes, {useHash: false}),
        SignalRModule.forRoot(createConfig),
        ContextMenuModule.forRoot(),
        ShContextMenuModule,
        ImageViewerModule,
        MatDatepickerModule
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    EmailService,
    ApprovedEmailService,
    DisapprovedEmailService,
    ThemeService,
    LoginService,
    EmailAccountService,
    ThemeCriteriaService,
    AuthGuardService,
    ConnectionService,
    DashboardService,
    ApprovalCriteriaService,
    FileFunctionalityService,
    CollaboratorSetupService,
    ErrorLogService,
    CollabItemService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    SubscriptionService,
    ApprovedAttachmentService,
    PendingAttachmentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
