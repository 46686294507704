import { Injectable } from '@angular/core';
import {Http} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import {ConnectionService} from './connection.service';
import {Observable} from 'rxjs';
import {FileHistory} from '../Entities/FileHistory';
import {GDPRLog} from '../Entities/GDPRLog';
import {LoginService} from './login.service';
import {GdprLogPageModel} from '../Entities/GdprLogPageModel';

@Injectable({
  providedIn: 'root'
})
export class GDPRLogService {

  private apiUrl;

  constructor(  private http: Http,
                private httpClient : HttpClient,
                private connectionService: ConnectionService,
                private loginService: LoginService) {
    this.apiUrl = this.connectionService.GetConnectionUrl() + "api/gdprlog";

  }
  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }
  getAll(): Observable<GDPRLog[]> {
    return this.httpClient
      .get<GDPRLog[]>(this.apiUrl, { headers: this.getAuthenticationHeader() });
  }
  getHandledScanGDPRLogs(): Observable<GDPRLog[]> {
    return this.httpClient
      .get<GDPRLog[]>(this.apiUrl + "/GetHandledGDPRLogs", {headers: this.getAuthenticationHeader() });
  }
  getUnhandledScanGDPRLogs(): Observable<GDPRLog[]> {
    return this.httpClient
      .get<GDPRLog[]>(this.apiUrl + "/GetUnhandledScanGDPRLogs", {headers: this.getAuthenticationHeader() });
  }
  scanForGDPR(): Observable<GDPRLog[]> {
    return this.httpClient
      .get<GDPRLog[]>(this.apiUrl + "/ScanForGDPR/" + this.loginService.getLoggedInUserID(), {headers: this.getAuthenticationHeader() });
  }
  deleteAll() : Observable<GDPRLog[]> {
    return this.httpClient
      .get<GDPRLog[]>(this.apiUrl + "/DeleteAll", {headers: this.getAuthenticationHeader() });
  }
  update(log: GDPRLog): Observable<GDPRLog> {
    return this.httpClient
      .put<GDPRLog>(this.apiUrl, JSON.stringify(log), {headers: this.getAuthenticationHeader() });
  }
  handleGdprLogs(gdprLogPageModel: GdprLogPageModel): Observable<GdprLogPageModel> {
    gdprLogPageModel.User = this.loginService.getLoggedInUserEmailAddress();
    return this.httpClient
      .post<GdprLogPageModel>(this.apiUrl + "/HandleGDPRLogs", JSON.stringify(gdprLogPageModel),{headers: this.getAuthenticationHeader() });
  }
  getPaginated(gdprLogPageModel: GdprLogPageModel): Observable<GdprLogPageModel> {
    return this.httpClient
      .post<GdprLogPageModel>(this.apiUrl + "/GetUnhandledScanGDPRLogspaginated", JSON.stringify(gdprLogPageModel), {headers: this.getAuthenticationHeader() });
  }
}
