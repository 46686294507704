import { Injectable } from "@angular/core";
import { Observable } from "rxjs/index";
import { map } from "rxjs/operators";
import { ApprovedAttachment } from "../Entities/EmailEntities/ApprovedAttachment";
import { ConnectionService } from "./connection.service";
import 'rxjs/add/operator/map';
import 'rxjs-compat/add/operator/catch';
import {Http, RequestOptions, ResponseContentType} from '@angular/http';
import {HttpClient, HttpEvent, HttpHeaders} from '@angular/common/http';
import {Email} from '../Entities/EmailEntities/Email';

@Injectable()
export class ApprovedAttachmentService {
  private apiUrl;
  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private connectionService: ConnectionService
  ) {
    this.apiUrl = this.connectionService.GetConnectionUrl() + 'api/ApprovedFile';
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  returnDownloadURL(id: number): string {
    return this.apiUrl + '/Download/' + id;
  }

  // returns an email with matching id from the database, if it exists. Else writes an error in the browsers console.
  get(id: string): Observable<ApprovedAttachment> {
    return this.httpClient
      .get<ApprovedAttachment>(this.apiUrl + '/' + id, { headers: this.getAuthenticationHeader() });
      //.pipe(map(r => r.json() as ApprovedAttachment));
  }

  putFile(file: ApprovedAttachment): Observable<ApprovedAttachment> {
    //console.log(JSON.stringify(email));
    return this.httpClient.put<ApprovedAttachment>(this.apiUrl + '/' + file.ID, JSON.stringify(file), { headers: this.getAuthenticationHeader() });
  }

  uploadFiles(files: ApprovedAttachment[]): Observable<ApprovedAttachment[]> {

    return this.httpClient
      .post<ApprovedAttachment[]>(this.apiUrl + '/UploadFiles', JSON.stringify(files), {
        headers: this.getAuthenticationHeader()
      })
      //.pipe(map(r => r.json() as ApprovedAttachment[]));
  }

  getBase64Item(id: number): Observable<string> {
    return this.httpClient
      .get<string>(this.apiUrl + '/getFileBase64/' + id, { headers: this.getAuthenticationHeader() });
  }

  downloadFile(id: number): Observable<Blob> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      }),
      responseType: 'blob' as 'json',
    };
    return this.httpClient.get<File>(this.apiUrl + '/Download/' + id, options);
  }

  getServiceFileUrl(id: number): Observable<string> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      })
    };
    return this.httpClient.get<string>(this.apiUrl + '/OpenServiceFile/' + id, options);
  }

  transferDisapprovedFile(file : ApprovedAttachment): Observable<any>{

    return this.httpClient
      .post<ApprovedAttachment[]>(this.apiUrl + '/TransferDisapprovedFile', JSON.stringify(file), {
        headers: this.getAuthenticationHeader()
      });
      //.pipe(map(r => r.json() as ApprovedAttachment[]));
}
}
