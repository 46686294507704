import {Component, OnInit} from '@angular/core';
import {EmailType} from '../../Entities/EmailAccount';
import {User, UserAccountType, UserOperationState} from '../../Entities/User';
import {LoginService} from '../../services/login.service';
import {UserService} from '../../services/user.service';
import {EmailAccountService} from '../../services/email-account.service';

import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from "@angular/material";

@Component({
  selector: 'app-user-controller',
  templateUrl: './user-controller.component.html',
  styleUrls: ['./user-controller.component.css']
})
export class UserControllerComponent implements OnInit {

  users: User[];
  _user: User;

  constructor(private loginService: LoginService,
              private userService: UserService,
              private emailAccountService: EmailAccountService,
              private router: Router,
              private route : ActivatedRoute,
              private snackBar : MatSnackBar) {}

  ngOnInit() {
    this.getAllTheUsers();

    this.createAzureActiveAccountUser();
  }

  getAllTheUsers() {
    this.userService.getUsers().subscribe(us => {
      this.users = us;
    });
  }


  deleteUserAccount(user: User) {
    console.log('Is supposed to delete the user(and the emailaccounts belonging to the user??)' + user.ID);
    this.userService.deleteUser(user).subscribe(() => {
      this.getAllTheUsers();
    });
  }

  createAzureActiveAccountUser() {
    this.route.queryParams.subscribe(params => {
      let code = params['code'];
      let state = params['state'];
      let userID = +state;
      if (!!code) {
        this._user = new User();
        this._user.AccountType = UserAccountType.AzureADAccount;
        this._user.Password = code;
        if(userID == 0) {
          this.userService.createUser(this._user).subscribe(() => {
            this.snackBar.open('Bruger er nu oprettet', 'OK', {
              duration: 3000
            });
            this.getAllTheUsers();
          });
        }
      }
    });
    window.history.replaceState({},'',location.origin + '/users' );
  }
}
