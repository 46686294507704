import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoginService} from '../services/login.service';
import {ApprovedItem} from '../Entities/EmailEntities/ApprovedItem';
import {ClickHistory} from '../Entities/ClickHistory';
import {EmailService} from '../services/EmailServices/email.service';
import {ApprovedAttachmentService} from '../services/approved-attachment.service';
import {ApprovedAttachment} from '../Entities/EmailEntities/ApprovedAttachment';
import {ActivatedRoute, Router} from '@angular/router';
import {ClickHistoryService} from '../services/click-history.service';
import {FileFunctionalityService} from '../services/functionalities/file-functionality.service';

declare let $ : any;
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  loginBtnText: string;
  isLoggedIn: boolean;
  loggedInUserEmailAddress: string;
  isAdminLoggedIn: boolean;
  detailedEmail: any;
  detailedFile: ApprovedAttachment;

  clickHistoryItems: ClickHistory[];
  items: ApprovedItem[];
  private subscription: any;
  view: number;
  amount: number;
  public returnUrl: string;

  mailType: string = 'ApprovedEmail';

  historyToggledShow = false;


  constructor(private loginService: LoginService,
              private historyService: ClickHistoryService,
              private attachmentService: ApprovedAttachmentService,
              private emailService: EmailService,
              private fileFunctionality: FileFunctionalityService,
              private router: Router) {
    this.items = [];
  }

  ngOnInit() {
    this.amount = 5;
    this.getHistory();
    this.init();
    this.subscription = this.loginService.checkAccesToken().subscribe(x => {
      this.init();
      x !== false ? (this.loginBtnText = 'Log ud') : (this.loginBtnText = 'Log ind');
      this.isLoggedIn = x;
      this.getHistory();
    });

    this.historyService.hasHistoryChanged().subscribe(x => {
      this.getHistory();
    });

  }

  init() {
    if (this.loginService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.loggedInUserEmailAddress = this.loginService.getLoggedInUserEmailAddress();

      this.isAdminLoggedIn = this.loginService.isAdminLoggedIn();
      this.loginBtnText = 'Log ud';
    } else {
      this.isLoggedIn = false;
      this.loginBtnText = 'Log ind';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getLoggedInUserId()
  {
    return this.loginService.getLoggedInUserID();
  }


  tryLogout() {
    this.loginService.logout();
  }

  getHistory() {
    if (this.loginService.getLoggedInUserID())
      this.historyService.getClickHistoryByUser(this.loginService.getLoggedInUserID().toString(), this.amount).subscribe(hist => {
        this.clickHistoryItems = hist;
      });
  }

  setReturnUrl() {
    this.returnUrl = this.router.url.toString().substr(1);
  }

  GetEntryTitle(clickHistoryItem: ClickHistory) {
    if (clickHistoryItem.EntryType == this.mailType) {
      return clickHistoryItem.EntryTitle;
    }
    const fileNameArray = clickHistoryItem.EntryTitle.split('\\');

    return fileNameArray[fileNameArray.length - 1];
  }

  GetIconSource(clickHistoryItem: ClickHistory) {
    return this.fileFunctionality.GetIconSource(clickHistoryItem.EntryType, clickHistoryItem.EntryTitle);
  }

  historyItemClicked(clickHistoryItem: ClickHistory) {
    if(clickHistoryItem.EntryType == "Theme")
    {
      this.router.navigate(['/themes/', clickHistoryItem.EntryId])
    }
    else{
      this.router.navigate(['/emails/', clickHistoryItem.EntryId, clickHistoryItem.EntryType,  this.returnUrl])
    }
    //[routerLink]="['/emails/', clickHistoryItem.EntryId, clickHistoryItem.EntryType, returnUrl]"
  }
}

