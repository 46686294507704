import { User } from "../User";
import { EmailContent } from "./EmailContent";
import { PendingAttachment } from "./PendingAttachment";

export class Email {
  ID?: number;
  EmailContent?: EmailContent;
  User: User;
  IsChecked: boolean;
  IsRejected: boolean;
  IsApproved: boolean;
  RemoveDate: string;
  PendingAttachments?: PendingAttachment[];
  DisapprovedAttachments?: PendingAttachment[];
}

