import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User, UserAccountType} from '../../../Entities/User';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-user-create-view',
  templateUrl: './user-create-view.component.html',
  styleUrls: ['./user-create-view.component.css']
})
export class UserCreateViewComponent implements OnInit {

  @Output()
  public trySaveUserAccountEmitter = new EventEmitter();

  _user : User;
  userAccountType: UserAccountType = UserAccountType.EmailAccount;

  @Input()
  set user(value : User)
  {
    this._user = value;
    if(!!value) {
      if(value.AccountType != UserAccountType.EmailAccount && this._isEditing){
        this.emailAccountForm.controls['oldPassword'].disable();
      }
      this.userAccountType = value.AccountType;
    }
  }

  _isEditing: boolean;
  @Input()
  set isEditing(value : boolean)
  {
    this._isEditing = value;
    console.log(value);
  }


  @Input()
  errorMessage: string;


  _headlineText : string;

  @Input()
  set headlineText(text : string)
  {
    console.log(text);
    this._headlineText = text;
    this.formControlValueChanged(text);
  }

  emailAccountForm: FormGroup;

  passwordRepeat: string;


  constructor(private formBuilder: FormBuilder, private cdRef : ChangeDetectorRef) {
  }

  ngOnInit() {

    //this.formControlValueChanged(this._headlineText);
  }

  formControlValueChanged(text : any) {
    let val2Control = this.formBuilder.control({value:'', disabled: this._isEditing}, [Validators.required, Validators.email]);
    let val1Control = this.formBuilder.control(null, [Validators.required]);

    this.emailAccountForm = this.formBuilder.group({
      email: val2Control,
      password: [null, Validators.required],
      passwordRepeat: [null, Validators.required],
      oldPassword: [null, Validators.required],
      domainAddress: [null]
    });

    const oldPassControl = this.emailAccountForm.get('oldPassword');
    if (this._isEditing) {
      oldPassControl.setValidators([Validators.required]);
    }
    else {
      oldPassControl.clearValidators();
    }
    oldPassControl.updateValueAndValidity();
  }

  saveThisUserAccount(userAccountType: UserAccountType) {
    this._user.AccountType = userAccountType;

        if(this._user.Password == this.passwordRepeat) {
          this.trySaveUserAccountEmitter.emit(Object.assign({}, this._user));
        }
        else{
          this.errorMessage = ""
        }

    this._user.Password = "";
    this._user.OldPassword = "";
    this.passwordRepeat = "";
    this.cdRef.detectChanges();
  }

  registerWithAzureActiveDirectory() {
    let clientId = 'f6c12ac1-5678-41e2-a156-45a48bcf6943';
    let redirectUri = location.origin + '/users';
    let operationState = 0;
    if (this._isEditing) {
      operationState = this._user.ID;
      let redirectUri = location.origin + '/user-detail/'
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+ clientId +'&redirect_uri='+ redirectUri +'&response_type=code&scope=user.read%20openid%20profile%20offline_access&prompt=select_account&state='+operationState;
    }
    else{
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+ clientId +'&redirect_uri='+ redirectUri +'&response_type=code&scope=user.read%20openid%20profile%20offline_access&prompt=select_account&state='+operationState;
    }

  }
}
