import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { LoginService } from "../services/login.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) {}

  //Checks if the user is logged in and if not, the user is routed to the login page.
  //Normally used to prevent unauthorized access to specific routes.
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isLoggedIn = this.loginService.isLoggedIn();
    if (!isLoggedIn) {
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: state.url }
      });
    }
    return isLoggedIn;
  }
}
