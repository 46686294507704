import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Email} from '../../../Entities/EmailEntities/Email';
import {PendingAttachment} from '../../../Entities/EmailEntities/PendingAttachment';
import {PaginateModel} from '../../../Entities/PaginateModel';
import {EmailService} from '../../../services/EmailServices/email.service';
import {PendingAttachmentService} from '../../../services/pending-attachment.service';
import {EmailFileUpdate} from '../../../Entities/EmailFileUpdate';
import {forEach} from '@angular/router/src/utils/collection';
import {ActivatedRoute} from '@angular/router';
import {DisapprovedEmailService} from '../../../services/EmailServices/disapproved-email.service';
import {EmailFilterModel} from '../../../Entities/EmailFilterModel';
import {ApprovalCriteria} from '../../../Entities/ApprovalCriteria';
import {ApprovalCriteriaService} from '../../../services/approval-criteria.service';
import {LoginService} from '../../../services/login.service';
import {Observable, Subscription} from 'rxjs';
import {DisapprovedAttachmentService} from '../../../services/disapproved-attachment.service';

export enum ItemSiteType {
  Pending = 'Pending',
  Rejected = 'Rejected'
}


@Component({
  selector: 'app-email-filter',
  templateUrl: './email-filter.component.html',
  styleUrls: ['./email-filter.component.css']
})
export class EmailFilterComponent implements OnInit {
  uncheckedEmails: Email[];
  emailsCount: number;
  isLoadingData: boolean;
  currentPage: number;
  pageSize: number;
  detailedFile: PendingAttachment;
  detailedEmail: Email;
  view: number;
  routeParams: any;
  yScrollPosition: number;
  idOfNewlyAddedCriterionFromModal: number;

  siteType: ItemSiteType = ItemSiteType.Rejected;

  criteriaFilterConfig: string;
  private loggedInUserID: number;

  fetchEmailsSubscription : Subscription;

  constructor(private pendingEmailService: EmailService,
              private rejectedEmailService: DisapprovedEmailService,
              private pendingAttachmentService: PendingAttachmentService,
              private disapprovedAttachmentService: DisapprovedAttachmentService,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private approvalCriteriaService : ApprovalCriteriaService,
              private loginService : LoginService) {
  }

  ngOnInit() {
    this.loggedInUserID = Number(this.loginService.getLoggedInUserID());
    this.route.params.subscribe(params => {
      this.routeParams = params;
      this.Init();
    });


    /*this.pendingEmailService.getEmailsForUser(pageModel).subscribe(p => { console.log(p.Emails),
    (this.uncheckedEmails = p.Emails), (this.emailsCount = p.Total);
  });*/

  }

  Init() {
    this.pageSize = 50;
    this.currentPage = 1;
    let pageModel = new PaginateModel();
    pageModel.PageIndex = this.currentPage;
    pageModel.PageSize = this.pageSize;


    this.getEmailForPage(pageModel);
  }

  //Adjusts the pagination to match the new length when emails are approved and therefore removed from the list.
  //An Email object is parsed as parameter to method transferApprovedEmails in emailService.
  //The response is parsed into method getEmailsForPage which refreshes the list of pending emails.
  approveCheckedEmails(emails: Email[]) {
    this.updatePagination(this.emailsCount - emails.length);
    let pageModel = new PaginateModel();
    pageModel.PageSize = this.pageSize;
    pageModel.PageIndex = this.currentPage;
    this.uncheckedEmails = [];
    this.isLoadingData = true;

    if (this.siteType == ItemSiteType.Pending) {
      this.pendingEmailService.transferApprovedEmails(emails).subscribe(() => {
        this.getEmailForPage(pageModel);
      });
    } else if (this.siteType == ItemSiteType.Rejected) {
      console.log('Not made yet!');
      this.rejectedEmailService.transferApprovedEmails(emails).subscribe(() => {
        this.getEmailForPage(pageModel);
      }, error => {

        this.getEmailForPage(pageModel);
      });
    }
  }

  approveAndDisapproveEmails(emailFilterModel: EmailFilterModel) {
    this.updatePagination((this.emailsCount - emailFilterModel.emailsToDisapprove.length) - emailFilterModel.emailsToApprove.length);
    let pageModel = new PaginateModel();
    pageModel.PageSize = this.pageSize;
    pageModel.PageIndex = this.currentPage;
    this.uncheckedEmails = [];
    this.isLoadingData = true;

    this.pendingEmailService.transferApprovedEmails(emailFilterModel.emailsToApprove).subscribe(() => {
      this.pendingEmailService.transferDisapprovedEmails(emailFilterModel.emailsToDisapprove).subscribe(() => {
        this.getEmailForPage(pageModel);
      });
    });
  }

  //Transforms the emails from pending emails into approved emails.
  approveAllEmails() {
    this.updatePagination(0);
    let pageModel = new PaginateModel();
    pageModel.PageSize = this.pageSize;
    pageModel.PageIndex = this.currentPage;
    this.isLoadingData = true;
    this.uncheckedEmails = [];
    this.pendingEmailService.approveAllEmails().subscribe(() => {
      this.isLoadingData = false;
      let pageModel = new PaginateModel();
      pageModel.PageSize = this.pageSize;
      pageModel.PageIndex = this.currentPage;
      this.getEmailForPage(pageModel);
    });
  }

  //Transforms checked emails from pending emails to disapproved emails.
  disapproveCheckedEmails(emails: Email[]) {
    this.updatePagination(this.emailsCount - emails.length);
    let pageModel = new PaginateModel();
    pageModel.PageSize = this.pageSize;
    pageModel.PageIndex = this.currentPage;
    this.uncheckedEmails = [];
    this.isLoadingData = true;
    this.pendingEmailService.transferDisapprovedEmails(emails).subscribe(() => {
      this.getEmailForPage(pageModel);
    });
  }

  //Shows details of a clicked email.
  showDetailsOfPendingEmail(item: Email) {

    this.yScrollPosition = window.pageYOffset;

    if (this.siteType == ItemSiteType.Pending) {
      this.pendingEmailService.get('' + item.ID).subscribe(email => {
        console.log(email);
        this.detailedEmail = email;
      });
    } else if (this.siteType == ItemSiteType.Rejected) {
      this.rejectedEmailService.get('' + item.ID).subscribe(email => {
        console.log(email);
        this.detailedEmail = email;
      });
    }

    //this.detailedEmail = email;
  }


  //Gets a specific pending attachment from the database through method get() in PendingAttachmentService.
  //If an error accur, a snackBar is displayed.
  showDetailsOfPendingAttachment(pendingAttachment: PendingAttachment) {
    this.pendingAttachmentService.get('' + pendingAttachment.ID)
      .subscribe(content => this.detailedFile = content,
        err => this.snackBar.open('Filen er korrupt', 'OK', {duration: 4000})
      );
  }

  //Gets all pending emails through method getEmailsForUser in EmailService.
  getEmailForPage(pageModel: PaginateModel) {
    this.isLoadingData = true;

    if(this.fetchEmailsSubscription != null)
    {
      this.fetchEmailsSubscription.unsubscribe();
    }

    if (this.routeParams['type'] == ItemSiteType.Pending) {
      this.siteType = ItemSiteType.Pending;
      this.fetchEmailsSubscription = this.pendingEmailService.getEmailsForUser(pageModel).subscribe(model => {
        this.setDate(model);

      });
    } else if (this.routeParams['type'] == ItemSiteType.Rejected) {
      this.siteType = ItemSiteType.Rejected;
      this.fetchEmailsSubscription = this.rejectedEmailService.getEmailsForUser(pageModel).subscribe(model => {
        this.setDate(model);
      });
    }
  }


  setDate(pageModel: PaginateModel) {
    console.log(pageModel);
    this.uncheckedEmails = pageModel.Items;
    this.updatePagination(pageModel.Total);
    this.isLoadingData = false;
  }


  updatePagination(total: number) {
    this.emailsCount = total;
    if (total / this.pageSize <= this.currentPage) {
      this.currentPage = Math.ceil(total / this.pageSize);
    }
    if (total == 0) {
      this.pageSize = 0;
    }
  }

  setCurrentPage(currentPage: any) {
    this.currentPage = currentPage;
  }

  setPageSize(pageSize: any) {
    this.pageSize = pageSize;
  }

  setEmailToChecked(email: Email) {
    email.IsChecked = true;
  }

  setEmailFromChecked(email: Email) {
    email.IsChecked = false;
  }

  toggleEmailApproved(email: Email) {
    email.IsApproved = !email.IsApproved;

    if (email.IsApproved) {
      email.IsRejected = false;
    }

    email.PendingAttachments.forEach(file => {

      if (file != null) {
        file.IsApproved = email.IsApproved;

        if (file.IsApproved) {
          file.IsRejected = false;
        }
        if (this.siteType == ItemSiteType.Pending)
          this.pendingAttachmentService.update(file).subscribe(() => console.log('Updated file'));
      }
    });
    if (this.siteType == ItemSiteType.Pending)
      this.pendingEmailService.update(email).subscribe(() => console.log('Updated'));
  }

  toggleEmailRejected(email: Email) {
    email.IsRejected = !email.IsRejected;

    if (email.IsRejected) {
      email.IsApproved = false;
    }

    email.PendingAttachments.forEach(file => {

      if (file != null) {
        file.IsRejected = email.IsRejected;

        if (file.IsRejected) {
          file.IsApproved = false;
        }
        if (this.siteType == ItemSiteType.Pending)
          this.pendingAttachmentService.update(file).subscribe(() => console.log('Updated file'));
      }
    });
    if (this.siteType == ItemSiteType.Pending)
      this.pendingEmailService.update(email).subscribe(() => console.log('Updated'));
  }

  toggleFileApproved(update: EmailFileUpdate) {
    let email = this.uncheckedEmails.filter(x => x.ID == update.emailId)[0];

    if (email != null) {

      let file = email.PendingAttachments.filter(x => x.ID == update.fileId)[0];

      if (file != null) {
        if (!file.IsApproved == true && email.IsApproved == true || !file.IsApproved == false)
          file.IsApproved = !file.IsApproved;

        if (file.IsApproved) {
          file.IsRejected = false;
        }
        if (this.siteType == ItemSiteType.Pending)
          this.pendingAttachmentService.update(file).subscribe(() => console.log('Updated file'));
      }
    }
  }

  toggleFileRejected(update: EmailFileUpdate) {
    let email = this.uncheckedEmails.filter(x => x.ID == update.emailId)[0];

    if (email != null) {

      let file = email.PendingAttachments.filter(x => x.ID == update.fileId)[0];

      if (file != null) {
        file.IsRejected = !file.IsRejected;

        if (file.IsRejected) {
          file.IsApproved = false;
        }
        if (this.siteType == ItemSiteType.Pending)
          this.pendingAttachmentService.update(file).subscribe(() => console.log('Updated file'));
      }
    }
  }


  toggleEmailNeutral(email: Email) {
    email.IsApproved = false;
    email.IsRejected = false;

    email.PendingAttachments.forEach(file => {

      if (file != null) {
        file.IsRejected = false;
        file.IsApproved = false;
      }
      if (this.siteType == ItemSiteType.Pending)
        this.pendingAttachmentService.update(file).subscribe(() => console.log('Updated file'));
    });
    if (this.siteType == ItemSiteType.Pending)
      this.pendingEmailService.update(email).subscribe(() => console.log('Updated'));

  }


  downloadGivenFile(file) {
    if (this.routeParams['type'] == ItemSiteType.Pending) {
      this.pendingAttachmentService.downloadFile(file.ID).subscribe(blob => {
        this.appendFile(blob, file);
      });
    }
    else if(this.routeParams['type'] == ItemSiteType.Rejected)
    {
      this.disapprovedAttachmentService.downloadFile(file.ID).subscribe(blob => {
        this.appendFile(blob, file);
      });
    }
  }

  appendFile(blob : any, file : any)
  {
    console.log(blob);
    const fileUrl = URL.createObjectURL(blob);
    // for IE 10+
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, file.FileName);
    } else {
      const element = document.createElement('a');
      element.href = fileUrl;
      element.setAttribute('download', file.FileName);
      element.setAttribute('target', '_blank');
      document.body.appendChild(element);
      element.click();
    }
  }


  resetDetailedEmailView() {
    this.detailedEmail = null;
  }

  resetDetailedFileView() {
    this.detailedFile = null;
  }

  resetCriteriaFilterView() {
    let pageModel = new PaginateModel();
    pageModel.PageSize = this.pageSize;
    pageModel.PageIndex = this.currentPage;
    this.getEmailForPage(pageModel);

    this.criteriaFilterConfig = null;

  }

  //The id of the clicked attachment is parsed as parameter into method downloadFile in AttachmentService.
  //The response from the subscription is parsed into method createObjectURL to create a blob and download the file to the users local harddrive.
  downloadFile() {
    this.pendingAttachmentService.downloadFile(this.detailedFile.ID).subscribe(blob => {
      const fileUrl = URL.createObjectURL(blob);
      // for IE 10+
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, this.detailedFile.FileName);
      } else {
        const element = document.createElement('a');
        element.href = fileUrl;
        element.setAttribute('download', this.detailedFile.FileName);
        element.setAttribute('target', '_blank');
        document.body.appendChild(element);
        element.click();
      }
    });
  }

  showEmailFilterConfig() {
    this.criteriaFilterConfig = 'Hello world';
  }

  changePageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.isLoadingData = true;
    let pageModel = new PaginateModel();
    pageModel.PageSize = this.pageSize;
    pageModel.PageIndex = this.currentPage;
    this.getEmailForPage(pageModel);
    console.log(pageSize);
  }

  validateEmails(idOfNewlyAddedCriterion?) {
    this.isLoadingData = true;
    this.pendingEmailService.validateEmails(this.loggedInUserID, idOfNewlyAddedCriterion).subscribe(() => {
      console.log('Emails validated');
      this.isLoadingData = false;
      this.Init();
    });
  }

  approveCriteria(criteria: ApprovalCriteria) {
    criteria.User_ID = this.loggedInUserID;
    this.approvalCriteriaService.create(criteria).subscribe(addedCriterion => {
      this.idOfNewlyAddedCriterionFromModal = addedCriterion.ID;
      this.snackBar.open('Du har tilføjet et kriterie', 'OK', {
        duration: 3000
      });
    });
  }
}
