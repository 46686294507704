export class CollaboratorSetup{
  ID?: number;
  SaveInDatabase?: boolean;
  SaveInFolder?: boolean;
  FolderDirectory?: string;

  ServiceOwner?: string;
  ServiceToken?: string;
  ServiceCursor?: string;
  ServiceType?: number;
  Expired?: boolean;
  EmailsToNotify?: string;
  EmailsTONotifyList? : string[]
}

export enum SetupType {
  LocalFiles = 0,
  Dropbox = 1,
  OneDrive = 2,
  SharePoint = 3
}
