import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EmailAccount } from "../../Entities/EmailAccount";

@Component({
  selector: "app-email-account-controller-view",
  templateUrl: "./email-account-controller-view.component.html",
  styleUrls: ["./email-account-controller-view.component.css"]
})
export class EmailAccountControllerViewComponent implements OnInit {
  @Input()
  emailAccounts: EmailAccount[];

  @Output()
  public tryUpdateIsExportingAttProperty = new EventEmitter();
  @Output()
  public tryDeleteEmitter = new EventEmitter();
  @Output()
  public isPressed: boolean;

  @Input()
  routerLinkParam: any;

  constructor() {
    this.isPressed = false;
  }

  ngOnInit() {
  }

  //emits an object of EmailAccount to EmailAccountControllerComponent.
  tryDeleteAccount(account: EmailAccount) {
    if (confirm('Er du sikker på du vil slette ' + account.EmailAddress + ' fra systemet?')) {
      this.tryDeleteEmitter.emit(account);
    } else {
      // Do nothing!
    }

  }

  updateExportingAttProperty(account: EmailAccount) {
    this.tryUpdateIsExportingAttProperty.emit(account);
  }
}
