import { Injectable } from '@angular/core';
import {Http} from '@angular/http';
import {ConnectionService} from './connection.service';
import {Observable} from 'rxjs';
import {Theme} from '../Entities/Theme';
import {map} from 'rxjs/operators';
import {CollaboratorSetup} from '../Entities/CollaboratorSetup';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorSetupService {

  private apiUrl;

  constructor(
    private http: Http,
    private httpClient : HttpClient,
    private connectionService: ConnectionService)
  {
    this.apiUrl = this.connectionService.GetConnectionUrl() + "api/CollaboratorSetup";
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  getAll(): Observable<CollaboratorSetup[]> {
    return this.httpClient
      .get<CollaboratorSetup[]>(this.apiUrl, { headers: this.getAuthenticationHeader() });
    //.pipe(map(response => response.json() as CollaboratorSetup));
  }

  get(id : number): Observable<CollaboratorSetup> {
    return this.httpClient
      .get<CollaboratorSetup>(this.apiUrl + "/" + id, { headers: this.getAuthenticationHeader() });
    //.pipe(map(response => response.json() as CollaboratorSetup));
  }

  update(setup: CollaboratorSetup): Observable<CollaboratorSetup> {
    const url = `${this.apiUrl}`;
    return this.httpClient
      .put<CollaboratorSetup>(url, JSON.stringify(setup), { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as CollaboratorSetup));
  }

  create(setup: CollaboratorSetup): Observable<CollaboratorSetup> {
    const url = `${this.apiUrl}`;
    return this.httpClient
      .post<CollaboratorSetup>(url, JSON.stringify(setup), { headers: this.getAuthenticationHeader() });
    //.pipe(map(response => response.json() as CollaboratorSetup));
  }

  createSetupWithService(setup: CollaboratorSetup): Observable<CollaboratorSetup> {
    const url = `${this.apiUrl}`;
    return this.httpClient
      .post<CollaboratorSetup>(url + '/CreateSetupWithService', JSON.stringify(setup), { headers: this.getAuthenticationHeader() });
    //.pipe(map(response => response.json() as CollaboratorSetup));
  }

  delete(setupID: number): Observable<CollaboratorSetup> {
    const url = `${this.apiUrl}` + "/delete/" + setupID;
    return this.httpClient
      .delete<CollaboratorSetup>(url, { headers: this.getAuthenticationHeader() });
    //.pipe(map(response => response.json() as CollaboratorSetup));
  }


}
