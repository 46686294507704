import { Component, OnInit } from "@angular/core";
import { EmailAccount } from "../../Entities/EmailAccount";
import { LoginService } from "../../services/login.service";
import { EmailAccountService } from "../../services/email-account.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import {UserService} from '../../services/user.service';
import {User} from '../../Entities/User';
import {CollaboratorSetupService} from "../../services/collaborator-setup.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  feedback: { access_token: string, userName: string };
  errormsg: string;
  returnUrl: string;
  isExpired: boolean = false;
  successMessage: string;
  userId: string;
  resetToken: string;
  isChangePassword: boolean;
  authToken: string;

  constructor(
    private loginService: LoginService,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private setupService : CollaboratorSetupService,
    private emailAccountService: EmailAccountService,
  ) {
    this.route.queryParamMap.subscribe(queryParams => {
      this.userId = queryParams.get("userId");
      this.resetToken = queryParams.get("password_change_code")

      if(!!this.userId && !!this.resetToken) {
        this.isChangePassword = true;
      }
    })
  }
  ngOnInit() {
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/emails";

    const routeParams = this.route.params.subscribe(params => {
      let directLoginUsername = params['userame'];
      let directLoginPassword = params['password'];
      let directTheme = params['theme'];
      console.log(directTheme, directLoginPassword, directLoginUsername)
      if (!!directLoginUsername && !!directLoginPassword && !!directTheme) {
        this.returnUrl = 'themes/' + directTheme
        let user = new User();

        user.EmailAdress = directLoginUsername;
        user.Password = directLoginPassword;

        this.SignIn(user);
      }
    });

    //check for authentication token in the url
    this.route.queryParams.subscribe(params => {
      let code = params['code'];
      if (!!code) {
        let user = new User();
        user.AuthToken = code;
        this.SignIn(user);

        window.history.replaceState({},'',location.origin + '/' );
      }
    });
  }

  // username and password is parsed as parameter into the login method in LoginService.
  // If the user/pass exists in the db, the user is logged in and routed to the dashboard. If not, an error message is displayed.
  SignIn(user: User) {
    this.loginService.login(user).subscribe(
      data => (this.feedback = data),
      err => {
        const error = JSON.parse(err._body);

        if(error.error == 'Unauthorized') {
          this.errormsg = error.error_description;
          this.successMessage = undefined;
        } else {
          this.errormsg = err.toLocaleString();
          this.errormsg = "Email eller kodeord er forkert, prøv igen.";
          this.successMessage = undefined;
        }
        },
      () => {
        user.EmailAdress = this.feedback.userName;
        // TOP 220421 Added Added support for Microsoft login
        this.userService.getUserByUsername(user).subscribe(user => {
          this.loginService.setSession(user.ID, user.EmailAdress, user.IsAdmin).subscribe(x =>
            this.router.navigateByUrl(this.returnUrl).then(() => {

                this.emailAccountService.getByUserID(user.ID).subscribe(us => {
                  us.toString();

                  let expiredAccounts = us.filter(x => x.Expired == true);
                  if(expiredAccounts.length > 0) {
                    this.isExpired = true;
                    let snackBarRef = this.snackBar.open("Advarsel, en eller flere af dine emailkonti er udløbet!. ", "Forny", {
                      duration: 15000
                    });
                    snackBarRef.onAction().subscribe(()=> {
                      this.router.navigateByUrl("/user-detail/" + this.loginService.getLoggedInUserID());
                    });
                  }
                });

              if(user.IsAdmin) {
                this.setupService.getAll().subscribe(setups => {
                  let expiredSetups = setups.filter(x => x.Expired == true);
                  if(expiredSetups.length > 0) {
                    this.isExpired = true;
                    let snackBarRef = this.snackBar.open("Advarsel, en eller flere af opsætninger er udløbet!. ", "Forny", {
                      duration: 10000
                    });
                    snackBarRef.onAction().subscribe(()=> {
                      this.router.navigateByUrl("/setup");
                    });
                  }
                })
              }

              if(!this.isExpired) {
                this.snackBar.open("Du er logget ind.", "OK", {
                  duration: 3000
                });

              }
            },
              )
          );
        });
      }
    );
  }

  sendResetPasswordEmail(emailAddress: string) {
    this.loginService.sendResetPasswordEmail(emailAddress).subscribe(successMessage => {
        this.successMessage = successMessage;
        this.errormsg = undefined;
    }, error1 => {
      this.errormsg = error1._body.toString().replace(/"/g,'');
      this.successMessage = undefined;
      });
  }

  resetUsersPassword(passwordChangeModel: any) {
    this.loginService.changeForgottenPassword(passwordChangeModel).subscribe(successMessage => {
      this.successMessage = successMessage;
      this.errormsg = undefined;
      this.isChangePassword = false;
    }, error1 => {
      this.errormsg = error1._body.toString().replace(/"/g,'');
      this.successMessage = undefined;
      if(error1.statusText == "Unauthorized") {
        this.isChangePassword = false;
      }
    });
  }
}

