export enum CriteriaType {
  BlackList = "Disapprove",
  WhiteList = "Approve"
}
export class FilterCriterion{

  ID: number;
  UserID: number;
  Field: string;
  Auto: boolean;
  Value: string;
  Type: CriteriaType;
}

