import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Http } from '@angular/http';
import {TagModel, TagModelClass} from 'ngx-chips/core/accessor';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApprovedAttachment } from '../../Entities/EmailEntities/ApprovedAttachment';
import { ApprovedItem } from '../../Entities/EmailEntities/ApprovedItem';
import { PaginateModel } from '../../Entities/PaginateModel';
import { SearchCriteria } from '../../Entities/SearchCriteria';
import { Theme } from '../../Entities/Theme';
import { ConnectionService } from '../../services/connection.service';
import { Router } from '@angular/router';
import {FileFunctionalityService} from '../../services/functionalities/file-functionality.service';
import {ContextMenuComponent, ContextMenuService} from 'ngx-contextmenu';

import {EmailControllerComponent} from "./email-controller.component";
import {MatDialog} from "@angular/material/dialog";
import {CollabItemOption} from '../../Entities/CollabItem/CollabItemOption';
declare let $event : any;


@Component({
  selector: 'app-email-controller-view',
  templateUrl: './email-controller-view.component.html',
  styleUrls: ['./email-controller-view.component.css']
})
export class EmailControllerViewComponent implements OnInit {
  displayedColumns = ['userId', 'userName', 'progress', 'color'];

  @ViewChild(ContextMenuComponent) public basicMenu: ContextMenuComponent;
  public contextMenuActions = [
    {
      html: (item) => `Say hi!`,
      click: (item) => alert('Hi, ' + item.name),
      enabled: (item) => true,
      visible: (item) => item.type === 'type1',
    },
    {
      divider: true,
      visible: true,
    },
    {
      html: (item) => `Something else`,
      click: (item) => alert('Or not...'),
      enabled: (item) => false,
      visible: (item) => item.type === 'type1',
    },
  ];

  // Available Filter Options
  @Input()
  availableOptions_FileType : CollabItemOption[];
  @Input()
  availableOptions_Sender : CollabItemOption[];
  @Input()
  availableOptions_Recipiant : CollabItemOption[];

  // Selected Filter Options
  @Input()
  selectedOptions_FileType : string[] = [];
  @Input()
  selectedOptions_Sender : string[] = [];
  @Input()
  selectedOptions_Recipiant : string[] = [];


  @Input()
  isModal: boolean;
  @Input()
  isRejected: boolean;
  @Input()
  title: string;
  @Input()
  theme: Theme;
  @Input()
  approvedItems: ApprovedItem[];
  @Input()
  files: ApprovedAttachment[];
  @Input()
  emailsCount: number;
  @Input()
  attachmentCount: number;
  @Input()
  isLoadingData: boolean;
  @Input()
  currentPage: number;
  @Input()
  pageSize: number;
  @Input()
  isSubscribed: boolean;
  @Input()
  isAdmin: boolean;
  @Input()
  barChartLabels: string[];
  @Input()
  barChartData: any[];
  @Input()
  years: any[];
  @Input()
  selected: any;
  @Input()
  modalWindowId: string

  _searchInput : string;
  private themeSearchInput: string;

  @Input()
  set searchInput(value : string)
  {
    this._searchInput = value;
  }

  @Input()
  sortFields : string[];
  @Input()
  sortParameter: string;
  @Input()
  sortReversed: boolean;

  @Input()
  set themeIdFromRoute(value : number)
  {
    this.resetChart();
  }
  // NM 050220 - Add entity to theme >>
  dropDownThemes: Theme[];
  @Input()
  set _dropDownThemes(value: Theme[])
  {
    if(value) {
      this.dropDownThemes = value;
    }
  }
  selectedEntity : ApprovedItem;
  themeSearchString : string;
  // NM 050220 - Add entity to theme <<

  @Output()
  public trySetSelectedFileOptions = new EventEmitter();
  @Output()
  public trySetSelectedSenderOptions = new EventEmitter();
  @Output()
  public trySetSelectedRecipiantOptions = new EventEmitter();
  @Output()
  public tryChangeFilterOptions = new EventEmitter();
  @Output()
  public trySetSelectedYearEmitter = new EventEmitter();
  @Output()
  public tryCreateEmitter = new EventEmitter();
  @Output()
  public tryDeleteEmitter = new EventEmitter();
  @Output()
  public tryUpdateEmitter = new EventEmitter();
  @Output()
  public tryGetEmailsEmitter = new EventEmitter();
  @Output()
  public trySetCurrentPage = new EventEmitter();
  @Output()
  public trySetPageSize = new EventEmitter();
  @Output()
  public trySubscribeEmitter = new EventEmitter();
  @Output()
  public tryUnSubscribeEmitter = new EventEmitter();
  @Output ()
  public tryShowDetailsOfApprovedItem = new EventEmitter();
  @Output()
  public tryChangeSortParameter = new EventEmitter();
  @Output()
  public tryChangeSortReversed = new EventEmitter();
  @Output()
  public trySetSelectedMonthDirectly = new EventEmitter();
  @Output()
  public trySetSearchInput = new EventEmitter();
  // NM 050220 - Add entity to theme >>
  @Output()
  public tryGetThemesForDropdown = new EventEmitter();
  @Output()
  public tryIncludeEntityInTheme = new EventEmitter();
  @Output()
  public tryExcludeEntityFromTheme = new EventEmitter();
  // NM 050220 - Add entity to theme >>
  @Output()
  public tryOpenModalWindow = new EventEmitter();

  @Output()
  lastPageIndex: number;

  @Input()
  barLabelsValues: number[] = [];
  @ViewChild('fileInput')
  fileInput: ElementRef;
  @ViewChild('deleteBtn')
  DeleteBtn: ElementRef;

  sizeChoices = ['10', '25', '50', '75', '100'];

  barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false
  };
  barChartType = 'bar';
  barChartLegend = true;
  criteriaToDelete: SearchCriteria;
  hasBackspaced = false;
  toggleMonthArray: number[];
  form: FormGroup;
  loading = false;
  route: boolean = false;
  wasChanged: boolean;

  // gets set once the user filters by month
  chart: any;

  lastChartIndexClicked : number;

  constructor(private fb: FormBuilder,
              private http: Http,
              private connectionService: ConnectionService,
              private router: Router,
              private fileFunctionality : FileFunctionalityService,
              private modal : MatDialog,
              private contextMenuService: ContextMenuService) {

    this.criteriaToDelete = new SearchCriteria();
    this.currentPage = 1;
    this.pageSize = 10;
    this.toggleMonthArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.form = this.fb.group({
      name: ['', Validators.required],
      avatar: null
    });
  }

  ngOnInit() {
      const checkRoute = this.router.url;
      if(checkRoute === '/emails') {
        this.route = true;
      }

      // NM 230120 - Delete search with ESC/Escape >>
      let self = this;
      window.addEventListener("keydown", function (event) {
        switch (event.key) {
          case "Esc": // IE/Edge specific value
            self.ResetSearchInput();
            break;
          case "Escape":
            // Do something for "esc" key press.
            self.ResetSearchInput();
            break;
        }
      });
      // NM 230120 - Delete search with ESC/Escape <<
      // NM 050220 - Add entity to theme >>
      this.getThemesForDropDown("");
      // NM 050220 - Add entity to theme <<
  }

  // events
  // filter by month by clicking the bars in the bar chart
  chartClicked(e: any): void {
    this.chart = e.active[0]._chart;
    console.log(e.active[0]);
    let index = e.active[0]._index;

    if(index == this.lastChartIndexClicked)
    {
      index = null;
    }
    this.lastChartIndexClicked = index;

    this.trySetSelectedMonthDirectly.emit(index);
    this.getEmailForPage(1);
    this.updateColorsOfChartBars(e.active[0]._chart, index);

    /*const bar = e.active[0];
    if (bar) {
      const chart = bar._chart;
      this.chart = chart;
      const index = bar._index;
      if (!(chart.config.data.datasets[0].data[index] > 0)) {
        // if you press a bar with a value of 0, nothing should happen
        return;
      }
      // if the clicked index of toggleMonthArray already is 1 (means this month is toggled), we want to toggle it off
      const toggleMonthOff = this.toggleMonthArray[index] === 1;
      if (toggleMonthOff) {
        this.toggleMonthArray[index] = 0;
        this.trySetSelectedMonthDirectly.emit(null);
        this.getEmailForPage(1);
      } else {
        for (let i = 0; i < this.toggleMonthArray.length; i++) {
          this.toggleMonthArray[i] = 0;
        }
        this.toggleMonthArray[index] = 1;


        this.trySetSelectedMonthDirectly.emit(index);
        this.getEmailForPage(1);
      }
    }*/
  }


  updateColorsOfChartBars(chart: any, indexToColor: number) {
    for (let i = 0; i < 12; i++) {
      if (indexToColor != null && i == indexToColor) {
        this.barChartData[0].backgroundColor[i] = '#003e68';
      } else {
        this.barChartData[0].backgroundColor[i] = '#007acc';
      }
      chart.update();
    }


  // color the bars based on the toggleMonthArray
  /*updateColorsOfChartBars(chart: any) {
    for (let i = 0; i < this.toggleMonthArray.length; i++) {
      if (this.toggleMonthArray[i] === 0) {
        this.barChartData[0].backgroundColor[i] = '#007acc';
      } else {
        this.barChartData[0].backgroundColor[i] = '#003e68';

      }
      chart.update();
    }
  */}

  chartHovered(e: any): void {
    console.log(e);
  }

  onAdding(tag: any): Observable<TagModel> {
    const confirm = window.confirm(
      'Do you really want to add this tag?' + tag.display
    );
    return of(tag).pipe(filter(() => confirm));
  }

  onRemoving(tag: TagModel): Observable<TagModel> {
    const confirm = window.confirm('Do you really want to remove this tag?');
    return of(tag).pipe(filter(() => confirm));
  }

  tryRemoveSearchcriteria() {
    this.trySetCurrentPage.emit(this.currentPage);
    this.trySetPageSize.emit(this.pageSize);
    this.tryDeleteEmitter.emit(this.criteriaToDelete);
  }

  tryAddSearchCriteria(criteria: any) {
    this.trySetCurrentPage.emit(this.currentPage);
    this.trySetPageSize.emit(this.pageSize);
    const newCriteria = new SearchCriteria();
    newCriteria.Criteria = criteria.display;
    this.tryCreateEmitter.emit(newCriteria);
  }

  setOrderBy(order: string) {
    if (this.sortParameter === order) {
      this.tryChangeSortReversed.emit(!this.sortReversed);
    } else {

      this.tryChangeSortParameter.emit(order);
      this.tryChangeSortReversed.emit(false);
    }

    this.getEmailForPage(this.currentPage);
  }

  setCriteriaToDelete(criteria: SearchCriteria, bool: Boolean) {
    if (!bool) {
      this.theme.SearchCriterias.push(criteria);
    }
    this.criteriaToDelete = criteria;
    this.DeleteBtn.nativeElement.focus();
    this.DeleteBtn.nativeElement.click();
  }

  CallDeleteEvent(criteria: SearchCriteria) {
    this.DeleteBtn.nativeElement.focus();
    this.hasBackspaced = true;
    this.DeleteBtn.nativeElement.click();
  }

  getLastPageIndex() {
    return (this.lastPageIndex = Math.ceil(
      (this.emailsCount + this.attachmentCount) / this.pageSize
    ));
  }

  getEmailForPage(pageIndex: number) {

    if (pageIndex <= this.lastPageIndex && pageIndex > 0) {
      this.trySetCurrentPage.emit(pageIndex);
      this.tryGetEmailsEmitter.emit();
    }
  }

  setSearchedInput()
  {
    this.trySetSearchInput.emit(this._searchInput);
  }

  getSelectedMonth()
  {
    let month = 0;
    for (let i = 0; i < this.toggleMonthArray.length; i++) {
      if (this.toggleMonthArray[i] === 1) {
        const dateNow = new Date();
        //month = i + 1;
        month = i;
        if(this.selected == 0)
        {
          month = this.barLabelsValues[i];
          console.log("Set Month: " + month);

        }
      }
    }
    return month;
  }


  getPage(pageIndex: number) {

    let monthToggled = false;
    for (let i = 0; i < this.toggleMonthArray.length; i++) {
      if (this.toggleMonthArray[i] === 1) {
        monthToggled = true;
      }
    }

    if (monthToggled) {
      this.getEmailForPage(pageIndex);
    } else {
      this.getEmailForPage(pageIndex);
    }
  }

  trySubscribeToTheme() {
    this.trySubscribeEmitter.emit();
  }

  tryUnSubscribeFromTheme() {
    this.tryUnSubscribeEmitter.emit();
  }

  showApprovedItem(item: ApprovedItem) {
    if(!this.isModal)
    {
    this.tryShowDetailsOfApprovedItem.emit(item);
    }
  }

  setSelectedEntity(item: ApprovedItem)
  {
    // NM 060220 >>
    this.selectedEntity = item;
    // NM 060220 <<
  }

  onSelectYear($event) {
    console.log("Selecting year");
    this.trySetSelectedYearEmitter.emit($event.value);
    this.resetChart();
  }

  resetChart()
  {
    console.log(this.chart);
    this.lastChartIndexClicked = null;
    if(this.chart != null) {
      console.log("Updating color")
      this.updateColorsOfChartBars(this.chart, null);
    }
    /*let barToggled = false;
    for (let i = 0; i < this.toggleMonthArray.length; i++) {
      if (this.toggleMonthArray[i] === 1) {
        barToggled = true;
      }
    }
    if (this.chart && barToggled) {
      for (let i = 0; i < this.toggleMonthArray.length; i++) {
        this.toggleMonthArray[i] = 0;
      }
      this.updateColorsOfChartBars(this.chart, null);
    }*/
  }


  GetIconSource(approvedItem: ApprovedItem) {
    return this.fileFunctionality.GetIconSource(approvedItem.IsMail == 1 ? 'ApprovedEmail' : 'ApprovedFile', approvedItem.Subject);
  }

  GetFileIconSource(fileType : string)
  {
    return this.fileFunctionality.GetFileIconSource(fileType);
  }

  // CSK & KG Removes the path of the file
  GetFileName(approvedItem: ApprovedItem){
    if (approvedItem.IsMail){
      return approvedItem.Subject;
    }
    const fileNameArray = approvedItem.Subject.split('\\');
    const fileName = fileNameArray[fileNameArray.length - 1];
    return fileName;
  }

  tryEditSearchCriteria(input: string | TagModelClass) {
    console.log(input);
  }

  ResetSearchInput() {
    if(this._searchInput != "") {
      this._searchInput = "";
      this.setSearchedInput();
    }
    this.trySetSelectedFileOptions.emit([]);
    this.trySetSelectedSenderOptions.emit([]);
    this.trySetSelectedRecipiantOptions.emit([]);
  }


  getThemesForDropDown(searchInput : string)
  {
    this.tryGetThemesForDropdown.emit(searchInput);
  }

  includeEntityInTheme(theme : Theme)
  {
    const newCriteria = new SearchCriteria();
    newCriteria.Criteria = this.selectedEntity.GuidID;
    newCriteria.IsDefault = true;
    newCriteria.Themes = [];
    newCriteria.Themes.push(theme);
    this.tryIncludeEntityInTheme.emit(newCriteria);
  }

  excludeEntityFromTheme(theme : Theme)
  {
    const newCriteria = new SearchCriteria();
    newCriteria.Criteria = this.selectedEntity.GuidID;
    newCriteria.IsDefault = true;
    newCriteria.Themes = [];
    newCriteria.Themes.push(theme);
    this.tryExcludeEntityFromTheme.emit(newCriteria);
  }

  //Oppens a modal window with manually added criterias
  openModalWindow(isRejected: boolean) {
    let modal = this.modal.open(EmailControllerComponent);
    modal.componentInstance.isModal = true;
    modal.componentInstance.isRejected = isRejected;

    modal.componentInstance.pageModel.Theme = {ID: this.theme.ID };
    modal.componentInstance.pageModel.ThemeId = this.theme.ID;

    //modal.componentInstance.routeParams['themeId'] = this.theme.ID;
    modal.componentInstance.modalWindowId = modal.id;
    modal.afterClosed().subscribe(wasChanged => {
      if(wasChanged)
        this.getEmailForPage(this.currentPage);
    });
  }
  //puts manually added criteria to the banned list
  removeEntityFromIncluded(approvedItem : ApprovedItem, theme : Theme) {
    this.wasChanged = true;
    this.selectedEntity = approvedItem;
    this.excludeEntityFromTheme(theme);
  }

  //removes manually banned criteria from banned list
  removeEntityFromExcluded(approvedItem : ApprovedItem, theme : Theme) {
    this.wasChanged = true;
    this.selectedEntity = approvedItem;
    const newCriteria = new SearchCriteria();
    newCriteria.Criteria = "<>" + approvedItem.GuidID;
    newCriteria.IsDefault = true;
    newCriteria.Themes = [];
    newCriteria.Themes.push(theme);
    this.tryDeleteEmitter.emit(newCriteria);
  }

  //closes all modal windows (used to close manually added/removed window)
  closeModalWindow() {
    let modalWindow = this.modal.getDialogById(this.modalWindowId);
    modalWindow.close(this.wasChanged);
  }


  // FilterOptions >>

  EmitEventWithValue(emitter : EventEmitter<any>, value : string[])
  {
    emitter.emit(value);
  }

  IsOptionSelected(field_value: string, selectedFieldList: string[]): boolean {
    let f = selectedFieldList.find(x => x.toUpperCase() == field_value.toUpperCase())
    return f != undefined;
  }

  SetSelectedField(field_value: string, selectedFieldList: string[], emitter : EventEmitter<any>) {
    let dummyList = Object.assign(selectedFieldList);
    if(this.IsOptionSelected(field_value, selectedFieldList))
    {
      const index: number = dummyList.indexOf(field_value);
      if (index !== -1) {
        dummyList.splice(index, 1);
      }
    }
    else{
      dummyList.push(field_value);
    }

    emitter.emit(dummyList);
  }

  // FilterOptions <<


}
