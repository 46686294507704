import { Email } from "./EmailEntities/Email";
import { EmailAccount } from "./EmailAccount";

export enum UserAccountType {
  EmailAccount = 0,
  ADAccount = 1,
  AzureADAccount = 2
}

export enum UserOperationState {
  Create = 0,
  Update = 1
}

export class User {
  ID?: number;
  Inbox?: Email[];
  EmailAccount?: EmailAccount;
  IsAdmin?: boolean;
  EmailAdress?: string;
  GDPRAdmin?: boolean;
  Password?: string;
  OldPassword?: string;
  IsSuspended?: boolean;
  AccountType?: UserAccountType;
  DomainAddress?: string;
  AuthToken?: string;
}

