import { Injectable } from '@angular/core';
import {Http} from '@angular/http';
import {HttpClient} from '@angular/common/http';
import {ConnectionService} from './connection.service';
import {Observable} from 'rxjs';
import {CollaboratorSetup} from '../Entities/CollaboratorSetup';
import {FileHistory} from '../Entities/FileHistory';

@Injectable({
  providedIn: 'root'
})
export class FileHistoryService {

  private apiUrl;

  constructor(  private http: Http,
                private httpClient : HttpClient,
                private connectionService: ConnectionService)
  {
    this.apiUrl = this.connectionService.GetConnectionUrl() + "api/FileHistory";
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  get(id: number): Observable<FileHistory> {
    return this.httpClient
      .get<FileHistory>(this.apiUrl + "/" + id, { headers: this.getAuthenticationHeader() });
    //.pipe(map(response => response.json() as CollaboratorSetup));
  }
  getAllForFile(fileId: number): Observable<FileHistory[]> {
    return this.httpClient
      .get<FileHistory[]>(this.apiUrl + "/GetFileHistoriesForFile/" + fileId, { headers: this.getAuthenticationHeader() });
    //.pipe(map(response => response.json() as CollaboratorSetup));
  }
}
