import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {LoginService} from '../services/login.service';
import {MatSnackBar} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate{

  constructor(private loginService: LoginService,
              private router: Router,
              private snackBar: MatSnackBar) { }
//Checks if the user is an admin
  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let isLoggedInAsAdmin = this.loginService.isAdminLoggedIn();
    if (!isLoggedInAsAdmin) {
      this.router.navigate(["/emails"], {
        queryParams: { returnUrl: state.url }
      });
      this.snackBar.open('Du er ikke autoriseret til at se denne side', 'OK', {
        duration: 3000
      });
    }
    return isLoggedInAsAdmin;
  }
}
