import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Email } from "../../../Entities/EmailEntities/Email";
import { PendingAttachment } from "../../../Entities/EmailEntities/PendingAttachment";
import {LoginService} from '../../../services/login.service';
import {User} from '../../../Entities/User';
import {ApprovedEmailService} from '../../../services/EmailServices/approved-email.service';
import {ApprovedItem} from '../../../Entities/EmailEntities/ApprovedItem';
import {ActivatedRoute, Router} from '@angular/router';
import {Navigation} from 'selenium-webdriver';

export enum ItemType {
  ApprovedEmail = "Approved",
  PendingEmail = "Pending",
  RejectedEmail = "Rejected"
}

@Component({
  selector: "app-email-detail-view",
  templateUrl: "./email-detail-view.component.html",
  styleUrls: ["./email-detail-view.component.css"]
})
export class EmailDetailViewComponent implements OnInit {

  public ItemType = ItemType;

  email: Email;
  localIndexingData : string;

  @Input()
  public returnUrl : string;

  @Input()
  set approvedEmail(mail : Email)
  {
    this.email = mail;
    this.ItemTypeToShow = ItemType.ApprovedEmail;
  }

  @Input()
  set pendingEmail(mail : Email)
  {
    this.email = mail;
    this.ItemTypeToShow = ItemType.PendingEmail;
  }

  @Input()
  set rejectedEmail(mail : Email)
  {
    this.email = mail;
    this.ItemTypeToShow = ItemType.RejectedEmail;
  }

  @Output()
  public tryBackEmitter = new EventEmitter();
  @Output()
  public tryShowAttachment = new EventEmitter();
  @Output()
  public tryRejectEmail = new EventEmitter();
  @Output()
  public tryRejectFile = new EventEmitter();
  @Output ()
  public tryUpdateManualIndexingData = new EventEmitter();

  public manualIndexingDataInputSelected: boolean;
  public ItemTypeToShow : ItemType;

  public loggedInUserID : string;
  public isLoggedUserAdmin: boolean;


  constructor(private loginService : LoginService, private approvedEmailService: ApprovedEmailService, private router : Router) {}

  ngOnInit() {
    this.loggedInUserID = this.loginService.getLoggedInUserID();
    this.isLoggedUserAdmin = this.loginService.isAdminLoggedIn();
    this.localIndexingData = this.email.EmailContent.ManualIndexingData;

    // Scroll to top
    document.documentElement.scrollTop = 0;

    // NM 230120 - Delete search with ESC/Escape >>
    let self = this;
    window.addEventListener("keydown", function (event) {
      switch (event.key) {
        case "Esc": // IE/Edge specific value
          self.back();
          break;
        case "Escape":
          // Do something for "esc" key press.
          self.back();
          break;
      }
    });
    // NM 230120 - Delete search with ESC/Escape <<
  }

  //Method to emit an action event to either EmailFilterComponent or EmailControllerComponent.
  back() {
    if(!this.returnUrl) {
      this.tryBackEmitter.emit();
    }
    else{
      this.router.navigateByUrl(this.returnUrl);
    }
  }


  //Emits an object of PendingAttachment to EmailFilterComponent.
  showAttachment(pendingAttachment: PendingAttachment) {
    console.log("Trying to se att: " + pendingAttachment);
    this.tryShowAttachment.emit(pendingAttachment);
  }

  rejectEmail()
  {
    if (confirm('Er du sikker på at du vil afvise denne mail med dens vedhæftede filter? \r\n \r\n Mailen kan herefter findes under afviste mails')) {
      this.tryRejectEmail.emit(this.email);
    } else {
      // Do nothing!
    }
  }

  rejectFile(file : ApprovedEmailService)
  {
    if (confirm('Er du sikker på at du vil afvise denne fil? \r\n \r\n Filen kan herefter findes under afviste filer')) {
      this.tryRejectFile.emit(file);
    } else {
      // Do nothing!
    }
  }


  //KG and CSK updating manuel indexing data on email content 280519
  updateManualIndexingData(email: Email) {
    this.tryUpdateManualIndexingData.emit(email);
    //KG and CSK 290519 color change on textarea selected
    this.manualIndexingDataInputSelected = false;
  }

  //KG and CSK 290519 color change on textarea selected
  manualIndexingDataInputChanged() {
    this.manualIndexingDataInputSelected = true; //!this.manualIndexingDataInputSelected;
  }
}
