import { Injectable } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {LoginService} from "../services/login.service";

@Injectable({
  providedIn: 'root'
})
export class PasswordResetGuardService implements CanActivate{
  userIdFromParam: string;
  passwordChangeToken: string;
  isValid: boolean = false;

  constructor(private loginService: LoginService, private router: Router,private route: ActivatedRoute) { }

  async canActivate(route: ActivatedRouteSnapshot,
              routerState: RouterStateSnapshot) {
    const userIdParam = 'userId';
    const tokenParam = 'password_change_code';
    const hasUserId = route.queryParamMap.has(userIdParam);
    const hasCode = route.queryParamMap.has(tokenParam);

    if(hasUserId && hasCode) {
      this.userIdFromParam = route.queryParamMap.get(userIdParam);
      this.passwordChangeToken = route.queryParamMap.get(tokenParam);

      await this.loginService.validatePasswordChangeToken({UserId: this.userIdFromParam, PasswordResetCode: this.passwordChangeToken}).toPromise().then(isValid => {
        this.isValid = isValid;
      });
    }
    if(!this.isValid) {
      this.router.navigate(["/login"]);
    }
  return this.isValid;
}
}
