import {Injectable} from '@angular/core';
import {ClickHistory} from '../../Entities/ClickHistory';

@Injectable({
  providedIn: 'root'
})
export class FileFunctionalityService {

  constructor() {
  }

  GetIconSource(entryType: string, fileName: string) {
    let iconSource: String;

    switch (entryType) {
      case 'ApprovedFile':
        const fileTypeArray = fileName.split('.');
        const fileType = fileTypeArray[fileTypeArray.length - 1].toLowerCase();
        iconSource = this.GetFileIconSource(fileType);
        return iconSource;

      case 'ApprovedEmail' :
        iconSource = '../../../assets/file%20Icons/secured-letter.png';
        return iconSource;

      case 'Theme':
        iconSource = '/assets/images/EliteIT_icon.png';
        return iconSource;
    }
  }

  GetFileIconSource(fileType : string)
  {
    let iconSource = '';
    switch (fileType.toLowerCase()) {
      case 'mail':
        iconSource = '../../../assets/file%20Icons/secured-letter.png';
        break;
      case 'docx':
        iconSource = '../../../assets/file%20Icons/microsoft-word-2019.png';
        break;
      case 'xlsx':
        iconSource = '../../../assets/file%20Icons/microsoft-excel-2019.png';
        break;
      case 'pptx':
        iconSource = '../../../assets/file%20Icons/microsoft-powerpoint-2019.png';
        break;
      case 'txt':
      case 'xml':
        iconSource = '../../../assets/file%20Icons/windows-notepad-icon_293311.png';
        break;
      case 'pdf':
        iconSource = '../../../assets/file%20Icons/27_Pdf_File_Type_Adobe_logo_logos-512.png';
        break;
      case 'htm':
        iconSource = '../../../assets/file%20Icons/htm.png';
        break;
      case 'dll':
        iconSource = '../../../assets/file%20Icons/dll.png';
        break;
      case 'fob':
        iconSource = '../../../assets/file%20Icons/fob.png';
        break;
      case 'ps1':
        iconSource = '../../../assets/file%20Icons/ps1.png';
        break;
      case 'deploy':
        iconSource = '../../../assets/file%20Icons/config.png';
        break;
      case 'csv':
        iconSource = '../../../assets/file%20Icons/icons8-csv-40.png';
        break;
      case 'js':
        iconSource = '../../../assets/file%20Icons/js.png';
        break;
      case 'jpg':
      case 'png':
      case 'jpeg':
        iconSource = '../../../assets/file%20Icons/full-image.png';
        break;
      default:
        iconSource = '';
        break;
    }
    return iconSource;
  }
}
