import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CollaboratorSetup, SetupType} from '../../Entities/CollaboratorSetup';
import {CollaboratorSetupService} from '../../services/collaborator-setup.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {SearchCriteria} from '../../Entities/SearchCriteria';
declare var require: any;

var ServiceConfiguration = require('assets/config.json');

@Component({
  selector: 'app-collaborator-setup',
  templateUrl: './collaborator-setup.component.html',
  styleUrls: ['./collaborator-setup.component.css']
})
export class CollaboratorSetupComponent implements OnInit {
  collaboratorSetups : CollaboratorSetup[];
  setupToCreate : CollaboratorSetup;
  searchCriteria = []

  constructor(private setupService : CollaboratorSetupService,
              private snackBar: MatSnackBar,
              private route : ActivatedRoute) {
  }

  ngOnInit() {
    this.setupService.getAll().subscribe(setups => {
      this.collaboratorSetups = setups;
      console.log(setups);
      this.collaboratorSetups.forEach(setup => {
        if(setup.EmailsToNotify != ''){
          let emailsToNotifyList = setup.EmailsToNotify.split(",");
          setup.EmailsTONotifyList = emailsToNotifyList;
        }
        else {
          setup.EmailsTONotifyList = [];
        }
      });
      //this.collaboratorSetups.push({ID: 0, FolderDirectory: "", SaveInDatabase: false, SaveInFolder: false });
    });
    this.setupToCreate = new CollaboratorSetup();
    this.createSetupIfCodeInUrl();

  }

  updateSetup(setup : CollaboratorSetup)
  {
    if(!this.isSetupToBeCreated(setup)) {
      this.setupService.update(setup).subscribe(() => {
        this.snackBar.open('Setup opdateret', 'OK', {
          duration: 3000
        });
      });
    }
  }

  updateSetupAndView(setup : CollaboratorSetup)
  {
    this.setupService.update(setup).subscribe(() => {
      this.snackBar.open('Du har opdateret et kriterie', 'OK', {
        duration: 3000
      });
    });

    this.setupService.getAll().subscribe(setups => {
      this.collaboratorSetups = setups;
    });
  }

  updateSetupsInTable() {
    this.setupService.getAll().subscribe(setups => {
      this.collaboratorSetups = setups; console.log(setups);
      //this.collaboratorSetups.push({ID: 0, FolderDirectory: "", SaveInDatabase: false, SaveInFolder: false });
    });
    this.setupToCreate = new CollaboratorSetup();
  }

  addSetup(setup : CollaboratorSetup)
  {
    if(setup.FolderDirectory == null)
      setup.FolderDirectory = "";

    setup.ServiceType = SetupType.LocalFiles;
    this.setupService.create(setup).subscribe(() => {
      this.snackBar.open('Du har tilføjet et nyt setup', 'OK', {
        duration: 3000
      });
      this.updateSetupsInTable();
      this.setupToCreate = new CollaboratorSetup();
    });
  }

  removeSetup(setup : CollaboratorSetup){
    this.setupService.delete(setup.ID).subscribe(() => {
      this.snackBar.open('Du har nu fjernet en setup', 'OK', {
        duration: 3000
      });
      this.updateSetupsInTable();
    });
  }

  isSetupToBeCreated(setup : CollaboratorSetup) {
    return (this.collaboratorSetups.indexOf(setup) == this.collaboratorSetups.length - 1);
  }

  createSetupIfCodeInUrl() {
    this.route.queryParams.subscribe(params => {
      let code = params['code'];
      if (!!code) {
        let state = params['state'];
        let serviceNumber = state.slice(0, 1);
        let serviceID = state.slice(1);

        this.setupToCreate.FolderDirectory = '';
        this.setupToCreate.SaveInFolder = true;
        this.setupToCreate.SaveInDatabase = false;
        this.setupToCreate.ServiceToken = code;
        this.setupToCreate.ServiceType = +serviceNumber;
        this.createOrUpdateService(serviceID);
      }
    });
  }

  openLoginWindowForService(serviceNumber: number, serviceId? : number, serviceDirectory? : string) {
    if(serviceNumber == SetupType.Dropbox) {
      let clientId = ServiceConfiguration['dropboxServiceClientID'];
      let redirectUri = location.origin + '/setup';
      window.location.href = 'https://www.dropbox.com/oauth2/authorize?client_id='+ clientId +'&token_access_type=offline&force_reauthentication=true&response_type=code&redirect_uri='+ redirectUri +'&state='+ SetupType.Dropbox + serviceId;
    }
    else if(serviceNumber == SetupType.OneDrive) {
      let clientId = ServiceConfiguration['graphServiceClientID'];
      let redirectUri = location.origin + '/setup';
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+ clientId +'&redirect_uri='+ redirectUri +'&response_type=code&scope=user.read%20files.readwrite.all%20offline_access&prompt=select_account&state=' + SetupType.OneDrive + serviceId;
    }
    else if(serviceNumber == SetupType.SharePoint) {
      let clientId = ServiceConfiguration['graphServiceClientID'];
      let redirectUri = location.origin + '/setup';
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+ clientId +'&redirect_uri='+ redirectUri +'&response_type=code&scope=sites.readwrite.all%20user.read%20files.readwrite.all%20offline_access&prompt=select_account&state=' + SetupType.SharePoint + serviceId;
    }
  }

  createOrUpdateService(serviceId) {
    if(!isNaN(+serviceId)) {
      this.setupToCreate.ID = +serviceId;

      this.setupService.get(this.setupToCreate.ID).subscribe(existingSetup => {
        this.setupToCreate.FolderDirectory = existingSetup.FolderDirectory;
        this.setupToCreate.Expired = existingSetup.Expired;

        this.setupService.update(this.setupToCreate).subscribe(setup => {
          this.snackBar.open('Du har opdatered et setup', 'OK', {
            duration: 3000
          });
          this.updateSetupsInTable();
        });
      });
    }
    else{
      this.setupToCreate.ID = 0;

      this.setupService.create(this.setupToCreate).subscribe(setup => {
        this.snackBar.open('Du har tilføjet et nyt setup', 'OK', {
          duration: 3000
        });
        this.updateSetupsInTable();
      });
    }
    window.history.replaceState({},'',location.origin + '/setup' );
  }

  addSearchCriteria(collaboratorSetup: CollaboratorSetup, email: any) {
    collaboratorSetup.EmailsTONotifyList.pop();
    let isEmailValid = this.validateEmail(email.value)

    if(isEmailValid){
      collaboratorSetup.EmailsTONotifyList.push(email.value);
      collaboratorSetup.EmailsToNotify =  collaboratorSetup.EmailsTONotifyList.toString();
      this.setupService.update(collaboratorSetup).subscribe(setup => {
        this.snackBar.open('Du har opdatered et setup', 'OK', {
          duration: 3000
        });
      });
    }
  }

  removeSearchCriteria(collaboratorSetup: CollaboratorSetup, email: any) {
    collaboratorSetup.EmailsTONotifyList.splice(
      collaboratorSetup.EmailsTONotifyList.indexOf(
        collaboratorSetup.EmailsTONotifyList.find(
          x => x == email
        )
      ),
      1
    );
    collaboratorSetup.EmailsToNotify =  collaboratorSetup.EmailsTONotifyList.toString();
    this.setupService.update(collaboratorSetup).subscribe(setup => {
      this.snackBar.open('Du har opdatered et setup', 'OK', {
        duration: 3000
      });
    });
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
