import { Theme } from "./Theme";

export class ThemePaginateModel {
  PageSize: number;
  PageIndex: number;
  Total: number;
  Themes: Theme[];
  UserID: number;

  // 221019 NM
  SortParameter: string;
  SortReversed: boolean;
  SearchInput: string;
}
