import {Injectable} from '@angular/core';
import {EmailAccount} from '../Entities/EmailAccount';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Headers, Http} from '@angular/http';
import {ConnectionService} from './connection.service';
import {ApprovalCriteria} from '../Entities/ApprovalCriteria';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApprovalCriteriaService {

  params: string;
  feedback: string;
  apiUrl: string;


  constructor(
    //private http: Http,
    private httpClient: HttpClient,
    private connectionService: ConnectionService) {
    this.apiUrl = this.connectionService.GetConnectionUrl() + 'api/ApprovalCriteria';
  }

  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  create(approvalCriteria: ApprovalCriteria): Observable<ApprovalCriteria> {
    this.params = JSON.stringify(approvalCriteria);

    return this.httpClient
      .post<any>(this.apiUrl, this.params, {headers: this.getAuthenticationHeader()});
      //.pipe(map(resp => (this.feedback = resp.json())));
  }

  getAll(): Observable<ApprovalCriteria[]> {
    return this.httpClient
      .get<ApprovalCriteria[]>(this.apiUrl, {headers: this.getAuthenticationHeader()});

      //.pipe(map(response => response.json() as ApprovalCriteria[]));
  }

  getAllForUser(userId: number): Observable<ApprovalCriteria[]> {

    return this.httpClient
      .get<ApprovalCriteria[]>(this.apiUrl + '/GetAllForUser?userId=' + userId, {headers: this.getAuthenticationHeader()});
      //.pipe(map(response => response.json() as ApprovalCriteria[]));
  }


  get(id: number): Observable<ApprovalCriteria> {
    return this.httpClient
      .get<ApprovalCriteria>(this.apiUrl + '/' + id, {headers: this.getAuthenticationHeader()});
      //.pipe(map(response => response.json() as ApprovalCriteria));
  }

  put(approvalCriteria: ApprovalCriteria) {
    this.params = JSON.stringify(approvalCriteria);
    return this.httpClient
      .put<any>(this.apiUrl + '/' + approvalCriteria.ID, this.params, {headers: this.getAuthenticationHeader()});
      //.subscribe(resp => console.log(resp.ok));
  }

  delete(approvalCriteria: ApprovalCriteria) {
    return this.httpClient
      .delete<any>(this.apiUrl + '/' + approvalCriteria.ID, {headers: this.getAuthenticationHeader()});
      //.subscribe(resp => console.log(resp.ok));
  }


}
