import { User } from "./User";

export enum EmailType {
  Gmail = 1,
  Exchange = 2
}

export class EmailAccount {
  ID: number;
  EmailAddress?: string;
  Password?: string;
  User: User;
  Progress: number;
  RefreshToken: string;
  EmailType: EmailType;
  Expired: boolean;
  isExportingAtt: boolean;
}
