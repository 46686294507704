import { Injectable } from "@angular/core";
import {Observable, throwError} from 'rxjs';
import { Email } from "../../Entities/EmailEntities/Email";
import { Http } from "@angular/http";
import { map } from "rxjs/operators";
import { ConnectionService } from "../connection.service";
import { BarChartData } from "../../Entities/BarChartData";
import { PaginateModel } from "../../Entities/PaginateModel";
import { LoginService } from "../login.service";
import {HttpClient} from '@angular/common/http';

@Injectable()
export class DisapprovedEmailService {
  private apiUrl;

  constructor(
    //private http: Http,
    private httpClient: HttpClient,
    private connectionService: ConnectionService,
    private loginService: LoginService
  ) {
    this.apiUrl =
      this.connectionService.GetConnectionUrl() + "api/DisapprovedEmails";
  }

  private getAuthenticationHeader()
  {
    return this.connectionService.GetAuthorizationHeaderClient();
  }


  create(email: Email): Observable<Email> {
    return this.httpClient
      .post<Email>(this.apiUrl, JSON.stringify(email), { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as Email));
  }

  //return all emails from the database.
  getAll(): Observable<Email[]> {
    return this.httpClient
      .get<Email[]>(this.apiUrl, { headers: this.getAuthenticationHeader() })
      //.pipe(map(response => response.json() as Email[]));
  }

  delete(email: Email): Observable<Email> {
    const url = `${this.apiUrl}/${email.ID}`;
    return this.httpClient
      .delete<Email>(url, { headers: this.getAuthenticationHeader() })
      //.pipe(map(response => response.json() as Email));
  }

  //returns a Email with matching id from the database, if it exists. Else writes an error in the browsers console.
  get(id: string): Observable<Email> {
    return this.httpClient
      .get<Email>(this.apiUrl + "/" + id, { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as Email));
  }

  //Updates the Email in the database with matching id if any. Else writes an error in the browsers console.
  update(email: Email): Observable<Email> {
    const url = `${this.apiUrl}/${email.ID}`;
    return this.httpClient
      .put<Email>(url, JSON.stringify(email), { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as Email));
  }

  //Writes an error message in the browsers console.
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  /*getChartDataForAll() {
    let url = this.connectionService.GetConnectionUrl() + "api/chart";
    return this.httpClient
      .get<BarChartData>(url, { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as BarChartData));
  }*/

  getEmailsForPage(pageModel: PaginateModel): Observable<PaginateModel> {
    pageModel.Emails = [];
    pageModel.Total = 0;
    return this.httpClient
      .post<PaginateModel>(this.apiUrl + "/GetEmailsForPage", JSON.stringify(pageModel), {
        headers: this.getAuthenticationHeader()
      });
      //.pipe(map(response => response.json() as PaginateModel));
  }

  getEmailsForUser(pageModel: PaginateModel): Observable<PaginateModel> {
    let userID = Number(this.loginService.getLoggedInUserID());
    if (userID != NaN) {
      pageModel.Emails = [];
      pageModel.Total = 0;
      pageModel.UserID = userID;
      return this.httpClient
        .post<PaginateModel>(this.apiUrl + "/GetEmailsForUser", JSON.stringify(pageModel), {
          headers: this.getAuthenticationHeader()
        });
        //.pipe(map(response => response.json() as PaginateModel));
      // .pipe(map(response => response.json() as PaginateModel));
    }
  }


  transferApprovedEmails(emails: Email[]): Observable<any> {
    let userID = Number(this.loginService.getLoggedInUserID());
    if (userID != NaN) {
      return this.httpClient
        .post<boolean>(this.apiUrl + "/TransferApprovedEmails", JSON.stringify(emails), {
          headers: this.getAuthenticationHeader()
        });
      //.catch(this.handleExceptionError);
    }
  }

  private handleExceptionError(error: any) {
    console.log(error._body);
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.log(errMsg);
    return throwError(error);
  }
}

