import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {EmailAccount, EmailType} from '../../../Entities/EmailAccount';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../Entities/User';
import {ActivatedRoute} from '@angular/router';
declare var require: any;

var ServiceConfiguration = require('assets/config.json');

@Component({
  selector: "app-email-account-details-view",
  templateUrl: "./email-account-details-view.component.html",
  styleUrls: ["./email-account-details-view.component.css"]
})
export class EmailAccountDetailsViewComponent implements OnInit {
  @Input()
  emailAccount: EmailAccount;

  @Input()
  errorMessage: string;

  @Input()
  userId: string;

  @Input()
  loading: boolean;

  @Output()
  public saveEmitter = new EventEmitter();
  @Output()
  public tryUpdateGmailAccount = new EventEmitter();

  constructor(private route : ActivatedRoute) {}

  ngOnInit() {
    this.emailAccount = new EmailAccount();
    this.emailAccount.User = new User();

    this.updateAccountIfCodeInUrl();

    /*this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });*/
  }

  updateAccountIfCodeInUrl() {
    this.route.queryParams.subscribe(params => {
      let code = params['code'];
      if (!!code) {
        this.emailAccount.RefreshToken = code;
        this.saveEmitter.emit(this.emailAccount);
      }
    });
  }

  //emits an object of EmailAcccount to smart component EmailAccountDetailComponent.
  trySaveEmailAccount() {
    console.log(this.emailAccount.EmailType);
    if(this.emailAccount.EmailType === EmailType.Exchange) {
      let clientId = ServiceConfiguration['graphServiceClientID'];

      let redirectUri = location.origin + '/emailaccount-detail/';
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id='+ clientId +'&redirect_uri='+ redirectUri +'&response_type=code&scope=user.read%20mail.read%20offline_access&prompt=select_account&state=12345';
    }
    else if(this.emailAccount.EmailType === EmailType.Gmail) {
      this.tryUpdateGmailAccount.emit(this.emailAccount);

    }
  }
}
