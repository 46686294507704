import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BarChartData } from "../../Entities/BarChartData";
import { Email } from "../../Entities/EmailEntities/Email";
import { PaginateModel } from "../../Entities/PaginateModel";
import { ConnectionService } from "../connection.service";
import { LoginService } from "../login.service";
import {HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {EmailAccount} from '../../Entities/EmailAccount';
import {jsonpCallbackContext} from '@angular/common/http/src/module';

@Injectable()
export class ApprovedEmailService {
  private apiUrl;

  constructor(
    private httpClient: HttpClient,
    //private http: Http,

    private connectionService: ConnectionService,
    private loginService: LoginService
  ) {
    this.apiUrl =
      this.connectionService.GetConnectionUrl() + 'api/ApprovedEmails';
  }

  private getAuthenticationHeader()
  {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  create(email: Email): Observable<Email> {
    return this.httpClient
      .post<Email>(this.apiUrl, email, { headers: this.getAuthenticationHeader() });
  }

 // returns a Email with matching id from the database, if it exists. Else writes an error in the browsers console.
 get(id: string): Observable<Email> {
   return this.httpClient
     .get<Email>(this.apiUrl + '/' + id, { headers: this.getAuthenticationHeader() });
     // .pipe(map(response => response.json() as Email));
 }

  // Writes an error message in the browsers console.
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  /*getChartDataForAll(year: number, searchedInput : string) {
    const url = this.connectionService.GetConnectionUrl() + 'api/chart';

    let pageModel = new PaginateModel();
    pageModel.Year = year;
    pageModel.SearchInput = searchedInput;
    return this.httpClient
      .post<BarChartData>(url, JSON.stringify(pageModel),{
        headers: this.getAuthenticationHeader()
      });

  }*/

  /*getEmailsForPage(pageModel: PaginateModel): Observable<PaginateModel> {
    pageModel.Emails = [];
    pageModel.Total = 0;
    pageModel.TotalAttachments = 0;
    return this.httpClient
      .post<PaginateModel>(this.apiUrl + '/GetEmailsForPage', JSON.stringify(pageModel), {
        headers: this.getAuthenticationHeader()
      });
  }*/

  /*getDistinctYearsForApprovedItems(searchInput : string): Observable<number[]> {
    let pageModel = new PaginateModel();

    //KG 100919 New http request >>
    pageModel.SearchInput = searchInput;
    return this.httpClient
      .post<number[]>(this.apiUrl + '/GetDistinctYears/', JSON.stringify(pageModel),{
      headers: this.getAuthenticationHeader()
    });

    //return this.httpClient
    //  .get<number[]>(this.apiUrl + '/GetDistinctYears/' + searchInput, {
    //    headers: this.getAuthenticationHeader()
    //  });
    //KG 100919 <<
      //.pipe(map(response => response.json() as number[]));
  }*/

  transferDisapprovedEmail(email: Email): Observable<Email> {
    return this.httpClient
      .post<Email>(this.apiUrl + '/TransferDisapprovedEmail', JSON.stringify(email), { headers: this.getAuthenticationHeader() });
  }

  putEmail(email: Email): Observable<Email> {
    //console.log(JSON.stringify(email));
    return this.httpClient.put<Email>(this.apiUrl + '/' + email.ID, JSON.stringify(email), { headers: this.getAuthenticationHeader() });
  }
}

