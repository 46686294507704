import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PendingAttachment } from "../Entities/EmailEntities/PendingAttachment";
import { ConnectionService } from "./connection.service";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Email} from '../Entities/EmailEntities/Email';

@Injectable()
export class PendingAttachmentService {
  private apiUrl;
  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private connectionService: ConnectionService
  ) {
    this.apiUrl =
      this.connectionService.GetConnectionUrl() + "api/PendingAttachment";
  }


  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }


  // returns an email with matching id from the database, if it exists. Else writes an error in the browsers console.
  get(id: string): Observable<PendingAttachment> {
    return this.httpClient
      .get<PendingAttachment>(this.apiUrl + "/" + id, { headers: this.getAuthenticationHeader() })
      //.pipe(map(response => response.json() as PendingAttachment))
  }

  downloadFile(id: number): Observable<Blob> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      }),
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<File>(this.apiUrl + '/Download/' + id, options);
  }

  update(attachment: PendingAttachment): Observable<PendingAttachment> {
    const url = `${this.apiUrl}/${attachment.ID}`;
    return this.httpClient
      .put<PendingAttachment>(url, JSON.stringify(attachment), { headers: this.getAuthenticationHeader() });
      //.pipe(map(response => response.json() as PendingAttachment));
  }
}
