import { Injectable } from '@angular/core';
import {Http} from '@angular/http';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConnectionService} from './connection.service';
import {Observable} from 'rxjs';
import {PendingAttachment} from '../Entities/EmailEntities/PendingAttachment';

@Injectable({
  providedIn: 'root'
})
export class DisapprovedAttachmentService {

  private apiUrl;
  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private connectionService: ConnectionService
  ) {
    this.apiUrl =
      this.connectionService.GetConnectionUrl() + "api/DisapprovedAttachment";
  }


  private getAuthenticationHeader() {
    return this.connectionService.GetAuthorizationHeaderClient();
  }

  downloadFile(id: number): Observable<Blob> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      }),
      responseType: 'blob' as 'json'
    };
    return this.httpClient.get<File>(this.apiUrl + '/Download/' + id, options);
  }

}
