import { Email } from "./EmailEntities/Email";
import { ApprovedAttachment } from "./EmailEntities/ApprovedAttachment";
import { Theme } from "./Theme";
import { PendingAttachment } from "./EmailEntities/PendingAttachment";
import { ApprovedItem } from "./EmailEntities/ApprovedItem";
import {ItemFilterModel} from './CollabItem/ItemFilterModel';
export class PaginateModel {
  PageSize: number;
  PageIndex: number;
  Total: number;
  TotalAttachments: number;
  Items: Email[];
  Emails: Email[];
  ApprovedItems?: ApprovedItem[];
  ApprovedAttachments?: ApprovedAttachment[];
  PendingAttachments?: PendingAttachment[];
  Theme: Theme;
  UserID: number;
  Year: number;
  Month: number;
  SortParameter: string;
  SortReversed: boolean;
  SearchInput: string;
  ThemeId: number;
  Default?: boolean;
  isRejected?: boolean;
  FromDateTime?: Date;
  ToDateTime?: Date;

  SelectedFilterOptions?: ItemFilterModel = new ItemFilterModel();
}

//KG 100919 added new field ThemeId
